import i18n from '@/i18n';

const configFormFirstView = () => {
    return {
        configFieldCopy: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('landingPage.settingFirstView.copy'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldSubCopy: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('landingPage.settingFirstView.subCopy'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldSubCopy2: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('landingPage.settingFirstView.subCopy2'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldCampaignStartDateTime: {
            minuteStep: 1,
            placeholderTime: '',
            errorText: '',
            error: false,
            typesValidate: ['dateTime'],
            errorField: i18n.t('landingPage.settingFirstView.campaignStartDate')
        },
        configFieldCampaignEndDateTime: {
            minuteStep: 1,
            placeholderTime: '',
            errorText: '',
            error: false,
            typesValidate: ['dateTime'],
            errorField: i18n.t('landingPage.settingFirstView.campaignEndDate')
        },
        configFieldApplicationDeadline: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('landingPage.settingFirstView.applicationDeadline'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        }
    };
};

export { configFormFirstView };
