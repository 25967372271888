<template>
    <div></div>
</template>

<script>
export default {
    name: 'SettingBlocks',
    props: {
        dataSetting: {
            type: Object,
            required: true,
            default: () => null
        }
    }
};
</script>

<style lang="scss" scoped></style>
