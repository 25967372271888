<template>
    <div>
        <div class="table-responsive" style="height: 530px; overflow-y: auto; outline: none">
            <table class="table table-bordered custom__table--sticky">
                <thead>
                    <tr>
                        <th>大項目</th>
                        <th>中項目</th>
                        <th>小項目</th>
                        <th>詳細</th>
                        <th>パートナー</th>
                        <th>ツール</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(itemTscope1, kScope1) in tableScopeOrder">
                        <template v-if="itemTscope1.data && itemTscope1.data.length">
                            <template v-for="(itemTscope2, kScope2) in itemTscope1.data">
                                <template v-if="itemTscope2.data && itemTscope2.data.length">
                                    <template v-for="(itemTscope3, kScope3) in itemTscope2.data">
                                        <template v-if="itemTscope3.data && itemTscope3.data.length">
                                            <tr
                                                v-for="(itemTscope4, kScope4) in itemTscope3.data"
                                                :key="`${kScope4}=>${kScope3}=>${kScope2}=>${kScope1}`"
                                            >
                                                <td>
                                                    <div style="min-width: 80px">
                                                        {{ itemTscope1.value }}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div style="min-width: 100px">
                                                        {{ itemTscope2.value }}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div style="min-width: 100px">
                                                        {{ itemTscope3.value }}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div style="min-width: 100px">
                                                        {{ itemTscope4.value }}
                                                    </div>
                                                </td>
                                                <td style="width: 320px">
                                                    <div style="max-width: 320px">
                                                        <Multiselect
                                                            :id="`select-partner-corresponding-object${kScope4}=>${kScope3}=>${kScope2}=>${kScope1}`"
                                                            :value.sync="itemTscope4.partner_corresponding"
                                                            :options="listPartnerCorrespondence"
                                                            :config.sync="itemTscope4.configPartnerCorresponding"
                                                            @select="handleFilterTool(itemTscope4)"
                                                            @open="
                                                                handleOpenGetListPartnerCorresponding(
                                                                    null,
                                                                    itemTscope2,
                                                                    itemTscope3,
                                                                    itemTscope4,
                                                                    itemTscope4.configPartnerCorresponding
                                                                )
                                                            "
                                                            @remove="
                                                                () => {
                                                                    itemTscope4.tool_corresponding = null;
                                                                    itemTscope4.toolInternal = [];
                                                                }
                                                            "
                                                        />
                                                    </div>
                                                </td>
                                                <td style="width: 320px">
                                                    <div style="max-width: 320px">
                                                        <Multiselect
                                                            :id="`select-tool-corresponding-object${kScope4}=>${kScope3}=>${kScope2}=>${kScope1}`"
                                                            :value.sync="itemTscope4.tool_corresponding"
                                                            :options="itemTscope4.toolInternal"
                                                            :config="itemTscope4.configToolCorresponding"
                                                            @open="handleOpenListTool(itemTscope4)"
                                                            @select="handleSelectTool"
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                        <template v-else>
                                            <tr :key="`${kScope1}=>${kScope2}=>${kScope3}`">
                                                <td>
                                                    <div style="min-width: 80px">
                                                        {{ itemTscope1.value }}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div style="min-width: 100px">
                                                        {{ itemTscope2.value }}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div style="min-width: 100px">
                                                        {{ itemTscope3.value }}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div style="min-width: 100px"></div>
                                                </td>
                                                <td style="width: 320px">
                                                    <div style="max-width: 320px">
                                                        <Multiselect
                                                            :id="`select-partner-corresponding-object${kScope3}=>${kScope2}=>${kScope1}`"
                                                            :value.sync="itemTscope3.partner_corresponding"
                                                            :options="listPartnerCorrespondence"
                                                            :config.sync="itemTscope3.configPartnerCorresponding"
                                                            @select="handleFilterTool(itemTscope3)"
                                                            @open="
                                                                handleOpenGetListPartnerCorresponding(
                                                                    null,
                                                                    itemTscope2,
                                                                    itemTscope3,
                                                                    null,
                                                                    itemTscope3.configPartnerCorresponding
                                                                )
                                                            "
                                                            @remove="
                                                                () => {
                                                                    itemTscope3.tool_corresponding = null;
                                                                    itemTscope3.toolInternal = [];
                                                                }
                                                            "
                                                        />
                                                    </div>
                                                </td>
                                                <td style="width: 320px">
                                                    <div style="max-width: 320px">
                                                        <Multiselect
                                                            :id="`select-tool-corresponding-object${kScope3}=>${kScope2}=>${kScope1}`"
                                                            :value.sync="itemTscope3.tool_corresponding"
                                                            :options="itemTscope3.toolInternal"
                                                            :config="itemTscope3.configToolCorresponding"
                                                            @open="handleOpenListTool(itemTscope3)"
                                                            @select="handleSelectTool"
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                </template>
                                <template v-else>
                                    <tr :key="`${kScope1}=>${kScope2}`">
                                        <td>
                                            <div style="min-width: 80px">
                                                {{ itemTscope1.value }}
                                            </div>
                                        </td>
                                        <td>
                                            <div style="min-width: 100px">
                                                {{ itemTscope2.value }}
                                            </div>
                                        </td>
                                        <td>
                                            <div style="min-width: 100px"></div>
                                        </td>
                                        <td>
                                            <div style="min-width: 100px"></div>
                                        </td>
                                        <td style="width: 320px">
                                            <div style="max-width: 320px">
                                                <Multiselect
                                                    :id="`select-partner-corresponding-object${kScope2}=>${kScope1}`"
                                                    :value.sync="itemTscope2.partner_corresponding"
                                                    :options="listPartnerCorrespondence"
                                                    :config.sync="itemTscope2.configPartnerCorresponding"
                                                    @select="handleFilterTool(itemTscope2)"
                                                    @open="
                                                        handleOpenGetListPartnerCorresponding(
                                                            null,
                                                            itemTscope2,
                                                            null,
                                                            null,
                                                            itemTscope2.configPartnerCorresponding
                                                        )
                                                    "
                                                    @remove="
                                                        () => {
                                                            itemTscope2.tool_corresponding = null;
                                                            itemTscope2.toolInternal = [];
                                                        }
                                                    "
                                                />
                                            </div>
                                        </td>
                                        <td style="width: 320px">
                                            <div style="max-width: 320px">
                                                <Multiselect
                                                    :id="`select-tool-corresponding-object${kScope2}=>${kScope1}`"
                                                    :value.sync="itemTscope2.tool_corresponding"
                                                    :options="itemTscope2.toolInternal"
                                                    :config="itemTscope2.configToolCorresponding"
                                                    @open="handleOpenListTool(itemTscope2)"
                                                    @select="handleSelectTool"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </template>
                        <template v-else>
                            <tr :key="`<=${kScope1}`">
                                <td>
                                    <div style="min-width: 80px">
                                        {{ itemTscope1.value }}
                                    </div>
                                </td>
                                <td>
                                    <div style="min-width: 100px"></div>
                                </td>
                                <td>
                                    <div style="min-width: 100px"></div>
                                </td>
                                <td>
                                    <div style="min-width: 100px"></div>
                                </td>
                                <td style="width: 320px">
                                    <div style="max-width: 320px">
                                        <Multiselect
                                            :id="`select-partner-corresponding-object${kScope1}`"
                                            :value.sync="itemTscope1.partner_corresponding"
                                            :options="listPartnerCorrespondence"
                                            :config.sync="itemTscope1.configPartnerCorresponding"
                                            @select="handleFilterTool(itemTscope1)"
                                            @open="
                                                handleOpenGetListPartnerCorresponding(
                                                    itemTscope1,
                                                    null,
                                                    null,
                                                    null,
                                                    itemTscope1.configPartnerCorresponding
                                                )
                                            "
                                            @remove="
                                                () => {
                                                    itemTscope1.tool_corresponding = null;
                                                    itemTscope1.toolInternal = [];
                                                }
                                            "
                                        />
                                    </div>
                                </td>
                                <td style="width: 320px">
                                    <div style="max-width: 320px">
                                        <Multiselect
                                            :id="`select-tool-corresponding-object${kScope1}`"
                                            :value.sync="itemTscope1.tool_corresponding"
                                            :options="itemTscope1.toolInternal"
                                            :config="itemTscope1.configToolCorresponding"
                                            @open="handleOpenListTool(itemTscope1)"
                                            @select="handleSelectTool"
                                        />
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </template>
                    <tr></tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { partnersMethods } from '@/state/helpers';
import Multiselect from '@/components/Multiselect/main.vue';
import { handleDataScopePartner } from './index';
export default {
    components: {
        Multiselect
    },
    props: {
        dataTabTools: {
            type: Array,
            default: () => []
        },
        objScopeOrder: {
            type: Object,
            default: () => null
        },
        dataMasterOrderScope: {
            type: Array,
            default: () => []
        },
        dataForm: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            tableScopeOrder: this.dataTabTools,
            listDataMasterOrderScope: this.dataMasterOrderScope,
            listPartnerCorrespondence: [],
            tableScopeOrderTemp: [],
            form: this.dataForm
        };
    },
    methods: {
        ...partnersMethods,

        handleShowOptionOneFilterLv2(itemFilterLv2) {
            return (
                itemFilterLv2.value == '電話' ||
                itemFilterLv2.value == 'メール' ||
                itemFilterLv2.value == 'はがき回収' ||
                itemFilterLv2.value == 'WEBフォーム' ||
                itemFilterLv2.value == '応募数報告' ||
                itemFilterLv2.value == 'お問い合わせ報告' ||
                itemFilterLv2.value == '現物' ||
                itemFilterLv2.value == '当選者用フォーム'
            );
        },

        handleShowOptionOneFilterLv3(itemFilterLv3) {
            return itemFilterLv3.value == '応募数報告' || itemFilterLv3.value == 'お問い合わせ報告' || itemFilterLv3.value == '現物';
        },

        handleSelectTool() {
            this.tableScopeOrderTemp = handleDataScopePartner(this.tableScopeOrder, 'select');
        },

        handleEmitSaveScopeOrder(newScopeOrder) {
            const { scopeChecked, scopeRadio } = newScopeOrder;
            this.tableScopeOrder = this.listDataMasterOrderScope
                .flatMap((itemOrigin) => {
                    if (scopeChecked.msOrderLv1.includes(itemOrigin.id)) {
                        const defaultToolAndPartner1 = this.tableScopeOrderTemp.find((item) => item.master_order_scope_id === itemOrigin.id);
                        return {
                            id: itemOrigin.id,
                            value: itemOrigin.value,
                            data: itemOrigin.data
                                .flatMap((itemLv2) => {
                                    if (scopeChecked.msOrderLv2.includes(itemLv2.id)) {
                                        const defaultToolAndPartner2 = this.tableScopeOrderTemp.find(
                                            (item) => item.master_order_scope_id === itemLv2.id
                                        );

                                        return {
                                            id: itemLv2.id,
                                            value: itemLv2.value,
                                            data: this.handleShowOptionOneFilterLv2(itemLv2)
                                                ? itemLv2.data
                                                      .flatMap((itemLv3) => {
                                                          if (scopeRadio.msOrderLv2[itemLv2.id] === itemLv3.id) {
                                                              const defaultToolAndPartner3 = this.tableScopeOrderTemp.find(
                                                                  (item) => item.master_order_scope_id === itemLv3.id
                                                              );

                                                              return {
                                                                  id: itemLv3.id,
                                                                  value: itemLv3.value,
                                                                  partner_corresponding: defaultToolAndPartner3?.partner_id ?? null,
                                                                  configPartnerCorresponding: {
                                                                      error: false,
                                                                      isRemove: false,
                                                                      trackBy: 'id',
                                                                      label: 'name',
                                                                      loading: false
                                                                  },
                                                                  toolInternal: [],
                                                                  tool_corresponding: defaultToolAndPartner3?.tool_id ?? null,
                                                                  configToolCorresponding: {
                                                                      error: false,
                                                                      isRemove: false,
                                                                      trackBy: 'id',
                                                                      label: 'tool',
                                                                      loading: false
                                                                  }
                                                              };
                                                          }
                                                          return undefined;
                                                      })
                                                      .filter((oLv2) => oLv2 !== undefined)
                                                : itemLv2.data
                                                      .flatMap((itemLv3) => {
                                                          if (scopeChecked.msOrderLv3.includes(itemLv3.id)) {
                                                              const defaultToolAndPartner3 = this.tableScopeOrderTemp.find(
                                                                  (item) => item.master_order_scope_id === itemLv3.id
                                                              );
                                                              return {
                                                                  id: itemLv3.id,
                                                                  value: itemLv3.value,
                                                                  data: this.handleShowOptionOneFilterLv3(itemLv3)
                                                                      ? itemLv3.data
                                                                            .flatMap((itemLv4) => {
                                                                                if (scopeRadio.msOrderLv4[itemLv3.id] === itemLv4.id) {
                                                                                    const defaultToolAndPartner4 = this.tableScopeOrderTemp.find(
                                                                                        (item) => item.master_order_scope_id === itemLv4.id
                                                                                    );

                                                                                    return {
                                                                                        id: itemLv4.id,
                                                                                        value: itemLv4.value,
                                                                                        partner_corresponding:
                                                                                            defaultToolAndPartner4?.partner_id ?? null,
                                                                                        configPartnerCorresponding: {
                                                                                            error: false,
                                                                                            isRemove: false,
                                                                                            trackBy: 'id',
                                                                                            label: 'name',
                                                                                            loading: false
                                                                                        },
                                                                                        toolInternal: [],
                                                                                        tool_corresponding: defaultToolAndPartner4?.tool_id ?? null,
                                                                                        configToolCorresponding: {
                                                                                            error: false,
                                                                                            isRemove: false,
                                                                                            trackBy: 'id',
                                                                                            label: 'tool',
                                                                                            loading: false
                                                                                        }
                                                                                    };
                                                                                }
                                                                                return undefined;
                                                                            })
                                                                            .filter((oLv4) => oLv4 !== undefined)
                                                                      : itemLv3.data
                                                                            .flatMap((itemLv4) => {
                                                                                if (scopeChecked.msOrderLv4.includes(itemLv4.id)) {
                                                                                    const defaultToolAndPartner4 = this.tableScopeOrderTemp.find(
                                                                                        (item) => item.master_order_scope_id === itemLv4.id
                                                                                    );

                                                                                    return {
                                                                                        id: itemLv4.id,
                                                                                        value: itemLv4.value,
                                                                                        partner_corresponding:
                                                                                            defaultToolAndPartner4?.partner_id ?? null,
                                                                                        configPartnerCorresponding: {
                                                                                            error: false,
                                                                                            isRemove: false,
                                                                                            trackBy: 'id',
                                                                                            label: 'name',
                                                                                            loading: false
                                                                                        },
                                                                                        toolInternal: [],
                                                                                        tool_corresponding: defaultToolAndPartner4?.tool_id ?? null,
                                                                                        configToolCorresponding: {
                                                                                            error: false,
                                                                                            isRemove: false,
                                                                                            trackBy: 'id',
                                                                                            label: 'tool',
                                                                                            loading: false
                                                                                        }
                                                                                    };
                                                                                }
                                                                                return undefined;
                                                                            })
                                                                            .filter((oLv4) => oLv4 !== undefined),
                                                                  partner_corresponding: defaultToolAndPartner3?.partner_id ?? null,
                                                                  configPartnerCorresponding: {
                                                                      error: false,
                                                                      isRemove: false,
                                                                      trackBy: 'id',
                                                                      label: 'name',
                                                                      loading: false
                                                                  },
                                                                  toolInternal: [],
                                                                  tool_corresponding: defaultToolAndPartner3?.tool_id ?? null,
                                                                  configToolCorresponding: {
                                                                      error: false,
                                                                      isRemove: false,
                                                                      trackBy: 'id',
                                                                      label: 'tool',
                                                                      loading: false
                                                                  }
                                                              };
                                                          }
                                                          return undefined;
                                                      })
                                                      .filter((oLv3) => oLv3 !== undefined),
                                            partner_corresponding: defaultToolAndPartner2?.partner_id ?? null,
                                            configPartnerCorresponding: {
                                                error: false,
                                                isRemove: false,
                                                trackBy: 'id',
                                                label: 'name',
                                                loading: false
                                            },
                                            toolInternal: [],
                                            tool_corresponding: defaultToolAndPartner2?.tool_id ?? null,
                                            configToolCorresponding: {
                                                error: false,
                                                isRemove: false,
                                                trackBy: 'id',
                                                label: 'tool',
                                                loading: false
                                            }
                                        };
                                    }
                                    return undefined;
                                })
                                .filter((oLv2) => oLv2 !== undefined),
                            partner_corresponding: defaultToolAndPartner1?.partner_id ?? null,
                            configPartnerCorresponding: {
                                error: false,
                                isRemove: false,
                                trackBy: 'id',
                                label: 'name',
                                loading: false
                            },
                            toolInternal: [],
                            tool_corresponding: defaultToolAndPartner1?.tool_id ?? null,
                            configToolCorresponding: {
                                error: false,
                                isRemove: false,
                                trackBy: 'id',
                                label: 'tool',
                                loading: false
                            }
                        };
                    }
                    return undefined;
                })
                .filter((o) => o !== undefined);

            if (this.form.campaign_master_order_scope_partner.length > 0) {
                this.tableScopeOrder.forEach((lv1) => {
                    const lv1Obj = this.form.campaign_master_order_scope_partner.find((item) => item.master_order_scope.id === lv1.id);
                    if (lv1Obj) {
                        lv1.idResgited = lv1Obj.id;
                        lv1.partner_corresponding = lv1Obj.partner ?? null;
                        lv1.tool_corresponding = lv1Obj.tool ?? null;
                    }
                    lv1.data.forEach((lv2) => {
                        const lv2Obj = this.form.campaign_master_order_scope_partner.find((item) => item.master_order_scope.id === lv2.id);
                        if (lv2Obj) {
                            lv2.idResgited = lv2Obj.id;
                            lv2.partner_corresponding = lv2Obj.partner ?? null;
                            lv2.tool_corresponding = lv2Obj.tool ?? null;
                        }
                        lv2.data.forEach((lv3) => {
                            const lv3Obj = this.form.campaign_master_order_scope_partner.find((item) => item.master_order_scope.id === lv3.id);
                            if (lv3Obj) {
                                lv3.idResgited = lv3Obj.id;
                                lv3.partner_corresponding = lv3Obj.partner ?? null;
                                lv3.tool_corresponding = lv3Obj.tool ?? null;
                            }
                            lv3.data?.forEach((lv4) => {
                                const lv4Obj = this.form.campaign_master_order_scope_partner.find((item) => item.master_order_scope.id === lv4.id);
                                if (lv4Obj) {
                                    lv4.idResgited = lv4Obj.id;
                                    lv4.partner_corresponding = lv4Obj.partner ?? null;
                                    lv4.tool_corresponding = lv4Obj.tool ?? null;
                                }
                            });
                        });
                    });
                });
            }
        },

        async handleOpenGetListPartnerCorresponding(scopeLv1, scopeLv2, scopeLv3, scopeLv4, config) {
            let queryFilter = `limit=9999999`;
            let scopeAllLvId = null;
            if (scopeLv4) {
                scopeAllLvId = scopeLv2.id;
                queryFilter = queryFilter.concat(`&has[medium_item]=${scopeAllLvId}`);
            } else if (scopeLv3) {
                scopeAllLvId = scopeLv2.id;
                queryFilter = queryFilter.concat(`&has[medium_item]=${scopeAllLvId}`);
            } else if (scopeLv2) {
                scopeAllLvId = scopeLv2.id;
                queryFilter = queryFilter.concat(`&has[medium_item]=${scopeAllLvId}`);
            } else {
                scopeAllLvId = scopeLv1.id;
                queryFilter = queryFilter.concat(`&has[large_item]=${scopeAllLvId}`);
            }
            if (config.loading) return;
            config.loading = true;
            this.listPartnerCorrespondence = [];
            const resultPartnerCorresponding = await this.listPartners(queryFilter);
            config.loading = false;
            if (resultPartnerCorresponding.code !== 200) {
                this.listPartnerCorrespondence = [];
                return;
            }
            this.listPartnerCorrespondence = resultPartnerCorresponding.data;
        },

        handleFilterTool(object) {
            object.tool_corresponding = null;
            object.toolInternal = object.partner_corresponding.tools ?? [];
            this.tableScopeOrderTemp = handleDataScopePartner(this.tableScopeOrder, 'select');
        },

        async handleOpenListTool(itemObjTool) {
            if (itemObjTool.partner_corresponding) {
                let queryFilter = `filters[id]=${itemObjTool.partner_corresponding.id}`;
                if (itemObjTool.configToolCorresponding.loading) return;
                itemObjTool.configToolCorresponding.loading = true;
                itemObjTool.toolInternal = [];
                try {
                    const resultPartnerCorresponding = await this.listPartners(queryFilter);
                    itemObjTool.toolInternal = resultPartnerCorresponding.data[0].tools ?? [];
                } catch (error) {
                    console.log('error', error);
                } finally {
                    itemObjTool.configToolCorresponding.loading = false;
                }
            }
        }
    },
    watch: {
        dataTabTools: {
            handler(newVal) {
                this.tableScopeOrder = newVal;
            },
            immediate: true,
            deep: true
        },
        objScopeOrder: {
            handler(newVal) {
                if (newVal) {
                    this.handleEmitSaveScopeOrder(newVal);
                }
            },
            immediate: true,
            deep: true
        },
        tableScopeOrder: {
            handler(newVal) {
                this.$emit('update:dataTabTools', newVal);
            },
            deep: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
