function handleValidate(arrForm) {
    let check = true;

    arrForm.forEach((obj) => {
        if (!obj.return_date) {
            obj.configReturnDate.isRequired = true;
            obj.configReturnDate.error = true;
            check = false;
        } else {
            obj.configReturnDate.isRequired = false;
            obj.configReturnDate.error = false;
        }

        if (obj.shipping_company) {
            if (obj.shipping_company.length > 255) {
                obj.configShippingCompany.isMaxLength = true;
                obj.configShippingCompany.error = true;
                check = false;
            } else {
                obj.configShippingCompany.isMaxLength = false;
                obj.configShippingCompany.error = false;
            }
        }

        if (obj.province) {
            if (obj.province.length > 255) {
                obj.confingProvince.isMaxLength = true;
                obj.confingProvince.error = true;
                check = false;
            } else {
                obj.confingProvince.isMaxLength = false;
                obj.confingProvince.error = false;
            }
        }

        if (obj.instruction) {
            if (obj.instruction.length > 255) {
                obj.configInstruction.isMaxLength = true;
                obj.configInstruction.error = true;
                check = false;
            } else {
                obj.configInstruction.isMaxLength = false;
                obj.configInstruction.error = false;
            }
        }

        if (obj.shipping_return_fee) {
            if (obj.shipping_return_fee.length > 255) {
                obj.configShippingReturnFee.isMaxLength = true;
                obj.configShippingReturnFee.error = true;
                check = false;
            } else {
                obj.configShippingReturnFee.isMaxLength = false;
                obj.configShippingReturnFee.error = false;
            }
        }

        if (obj.tracking_number) {
            if (obj.tracking_number.length > 255) {
                obj.configTrakingNumber.isMaxLength = true;
                obj.configTrakingNumber.error = true;
                check = false;
            } else {
                obj.configTrakingNumber.isMaxLength = false;
                obj.configTrakingNumber.error = false;
            }
        }

        if (obj.storage_code) {
            if (obj.storage_code.length > 255) {
                obj.configStorageCode.isMaxLength = true;
                obj.configStorageCode.error = true;
                check = false;
            } else {
                obj.configStorageCode.isMaxLength = false;
                obj.configStorageCode.error = false;
            }
        }

        if (!obj.reason) {
            obj.configReason.isRequired = true;
            obj.configReason.error = true;
            check = false;
        } else {
            obj.configReason.isRequired = false;
            obj.configReason.error = false;
        }

        if (!obj.return_status) {
            obj.configReturnStatus.isRequired = true;
            obj.configReturnStatus.error = true;
            check = false;
        } else {
            obj.configReturnStatus.isRequired = false;
            obj.configReturnStatus.error = false;
        }
    });

    return check;
}

export { handleValidate };
