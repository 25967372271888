<template>
    <div>
        <div class="col-lg-12">
            <form class="needs-validation" autocomplete="off">
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.nameCampaign') }} </label>
                    <div class="col-sm-9">
                        {{ dataForm?.campaign?.name ?? '' }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.firstView') }} </label>
                    <div class="col-sm-9">
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.copy') }} </label>
                            <div class="col-sm-9">
                                <InputText
                                    :model.sync="dataForm.first_view.copy"
                                    :config.sync="configForm.configFieldCopy"
                                    @blur="handleValidateField(configForm.configFieldCopy)"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.subCopy') }} </label>
                            <div class="col-sm-9">
                                <InputText
                                    :model.sync="dataForm.first_view.sub_copy"
                                    :config.sync="configForm.configFieldSubCopy"
                                    @blur="handleValidateField(configForm.configFieldSubCopy)"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.subCopy2') }} </label>
                            <div class="col-sm-9">
                                <InputText
                                    :model.sync="dataForm.first_view.sub_copy_2"
                                    :config.sync="configForm.configFieldSubCopy2"
                                    @blur="handleValidateField(configForm.configFieldSubCopy2)"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.imageTitle') }} </label>
                            <div class="col-sm-9">
                                <UploadFileCommon @dataFile="handleFileImageTitle" title="ファイルアップロード" :id="`ImageTitle`" />
                                <div
                                    v-if="
                                        dataForm.first_view.title_image?.origin_name &&
                                        arrExtension.includes(dataForm.first_view.title_image.extension)
                                    "
                                    class="d-flex align-items-center my-2"
                                >
                                    <a
                                        target="_blank"
                                        :href="dataForm.first_view.title_image?.path"
                                        @click.prevent="
                                            downLoadFile(dataForm.first_view.title_image?.path, dataForm.first_view.title_image?.origin_name)
                                        "
                                        >{{ dataForm.first_view.title_image?.origin_name }}</a
                                    >
                                    <span class="ml-2" style="cursor: pointer; font-size: 20px" @click="dataForm.first_view.title_image = null"
                                        ><i class="ri-close-line" style="vertical-align: bottom"></i
                                    ></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.applicationPeriod') }} </label>
                    <div class="col-sm-9">
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                                >{{ $t('landingPage.settingFirstView.campaignStartDate') }}
                            </label>
                            <div class="col-sm-9">
                                <DateTime
                                    id="select-application_period-campaign-start-date-time"
                                    :full_date.sync="dataForm.application_period.campaign_start_date"
                                    :full_time.sync="dataForm.application_period.campaign_start_time"
                                    :config.sync="configForm.configFieldCampaignStartDateTime"
                                    :disabled="true"
                                    @blur="handleValidateField(configForm.configFieldCampaignStartDateTime)"
                                >
                                    <span v-if="configForm.configFieldCampaignStartDateTime.error" class="invalid-feedback d-block">{{
                                        configForm.configFieldCampaignStartDateTime.errorText
                                    }}</span>
                                </DateTime>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                                >{{ $t('landingPage.settingFirstView.campaignEndDate') }}
                            </label>
                            <div class="col-sm-9">
                                <DateTime
                                    id="select-application_period-campaign-end-date-time"
                                    :full_date.sync="dataForm.application_period.campaign_end_date"
                                    :full_time.sync="dataForm.application_period.campaign_end_time"
                                    :config.sync="configForm.configFieldCampaignEndDateTime"
                                    :disabled="true"
                                    @blur="handleValidateField(configForm.configFieldCampaignEndDateTime)"
                                >
                                    <span v-if="configForm.configFieldCampaignEndDateTime.error" class="invalid-feedback d-block">{{
                                        configForm.configFieldCampaignEndDateTime.errorText
                                    }}</span>
                                </DateTime>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                                >{{ $t('landingPage.settingFirstView.applicationDeadline') }}
                            </label>
                            <div class="col-sm-9">
                                <InputText
                                    :model.sync="dataForm.application_period.application_deadline"
                                    :config.sync="configForm.configFieldApplicationDeadline"
                                    @blur="handleValidateField(configForm.configFieldApplicationDeadline)"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.overview') }} </label>
                            <div class="col-sm-9">
                                <textarea row="3" v-model="dataForm.application_period.overview" class="form-control"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { InputText } from '@/components/Input';
import { validateField } from '@/utils/validate';
import { handleDownFile, IMAGE_EXTENSIONS } from '@/utils/format';

import DateTime from '@/components/DateCustoms/DateTime/main.vue';
import UploadFileCommon from '@/components/Uploads/Files/main.vue';

export default {
    name: 'SettingFirstViewAndTimeApplication',
    components: {
        InputText,
        DateTime,
        UploadFileCommon
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        },
        configForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {
            arrExtension: IMAGE_EXTENSIONS
        };
    },
    methods: {
        handleValidateField(configField) {
            validateField(configField);
        },

        handleFileImageTitle(file) {
            this.dataForm.first_view.title_image = file;
        },

        downLoadFile(path, name) {
            if (path) return handleDownFile(path, name);
        }
    }
};
</script>

<style lang="scss" scoped></style>
