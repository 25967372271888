<template>
    <div>
        <ModalCommon :id="`configModalActionPrize-${config?.id ?? ''}`" :config="config" @save="handleSave()" @close="handleClose()">
            <div v-if="!isLoading" class="col-lg-12">
                <form class="needs-validation" autocomplete="off">
                    <div class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                            >{{ $t('landingPage.settingPrize.namePrize') }} <span class="text-danger">*</span></label
                        >
                        <div class="col-sm-9">
                            <InputText
                                :model.sync="dataFormPrize.name"
                                :config.sync="configFormPrizeLP.configFieldNamePrize"
                                @blur="handleValidateField(configFormPrizeLP.configFieldNamePrize)"
                            />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingPrize.detailPrize') }} </label>
                        <div class="col-sm-9">
                            <Editor
                                v-if="isShowEditor"
                                :id="`configModalActionPrize-${config?.id ?? ''}`"
                                :model.sync="dataFormPrize.detail"
                                :modelEvents="`change keydown blur focus paste`"
                                :configInit="configEditor"
                                @init="onEditorInit"
                            />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                            >{{ $t('landingPage.settingPrize.targetNumber') }} <span class="text-danger">*</span></label
                        >
                        <div class="col-sm-9">
                            <InputText
                                :model.sync="dataFormPrize.target_number"
                                :config.sync="configFormPrizeLP.configFieldTargetNumber"
                                @blur="handleValidateField(configFormPrizeLP.configFieldTargetNumber)"
                            />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingPrize.imagePrize') }} </label>
                        <div class="col-sm-9">
                            <UploadFileCommon class="mb-3" @dataFile="handleFileImagePrize" title="ファイルアップロード" :id="`ImagePrize`" />
                            <div v-if="dataFormPrize.image" class="col-sm-3 px-0">
                                <img :src="dataFormPrize.image" alt class="img-fluid mx-auto d-block rounded" />
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingPrize.status') }} </label>
                        <div class="col-sm-9">
                            <div class="d-flex flex-wrap">
                                <div v-for="item in STATUS_SETTING_PRIZE" :key="item.id + 's'">
                                    <InputRadio
                                        class="mr-4"
                                        :model.sync="dataFormPrize.status"
                                        :name="`status_prize_setting`"
                                        :id="`status_prize_setting-${item.id}`"
                                        :value="item.id"
                                        :label="item.value"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div v-else class="d-flex justify-content-center align-items-center" style="height: 75vh">
                <LoadingIcon />
            </div>
        </ModalCommon>
    </div>
</template>

<script>
import { InputText, InputRadio } from '@/components/Input';
import { STATUS_SETTING_PRIZE } from '../../constants';
import { configFormPrizeLP } from './constants';
import { validateField, validateAllField } from '@/utils/validate';
import { showMessage } from '@/utils/messages';

import Editor from '@/components/Editor/main.vue';
import UploadFileCommon from '@/components/Uploads/Files/main.vue';
import ModalCommon from '@/components/Modal/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';

export default {
    name: 'ModalEditPrize',
    components: {
        InputText,
        InputRadio,
        Editor,
        UploadFileCommon,
        ModalCommon,
        LoadingIcon
    },
    props: {
        config: {
            type: Object,
            required: true,
            default: () => null
        },
        dataPrize: {
            type: Object,
            required: true,
            default: () => null
        },
        listPrizeRegistered: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    data() {
        return {
            STATUS_SETTING_PRIZE: STATUS_SETTING_PRIZE,
            configFormPrizeLP: configFormPrizeLP,
            objEditor: null,
            isShowEditor: false,
            isLoading: false,
            dataFormPrize: this.dataPrize,
            dataFormPrizeCopy: JSON.parse(JSON.stringify(this.dataPrize)),
            configEditor: {
                content_style: 'p { margin: 0 }',
                height: 600,
                menubar: true,
                plugins: [
                    'hr advlist autolink lists link image charmap',
                    'searchreplace visualblocks code fullscreen',
                    'print preview anchor insertdatetime media template',
                    'paste code help wordcount table code lists imagetools '
                ],
                toolbar:
                    'myCustomToolbarButton hr undo redo | formatselect | bold italic | \
                                          alignleft aligncenter alignright | \
                                          link image media | \
                                          bullist numlist outdent indent | help code\
                                          table tableinsertdialog tablecellprops tableprops | fullscreen ',
                table_toolbar:
                    'tableprops tabledelete tablemergecells tablesplitcells | tableinsertrowbefore tableinsertrowafter tablerowprops tabledeleterow | tableinsertcolbefore tableinsertcolafter tablecellprops tabledeletecol'
            }
        };
    },
    methods: {
        handleValidateField(configField) {
            validateField(configField);
        },

        onEditorInit(editor) {
            this.objEditor = editor;
        },

        handleFileImagePrize(file) {
            this.dataFormPrize.image = file;
        },

        handleSave() {
            let checkValidate = validateAllField(configFormPrizeLP);
            if (!checkValidate) return;
            const isUnique = this.listPrizeRegistered.some(
                (u) => u.name.toString().toLowerCase() == this.dataFormPrize.name.toString().toLowerCase()
            );
            if (isUnique) {
                showMessage(8080, this.$bvToast, `${this.$t('pageCampaign.tabPrize.namePrize')}がすでに登録されています。`);
                return;
            }

            if (this.dataFormPrize?.id || this.dataFormPrize?.actionPrize == 'Edit') {
                this.$emit('updateData', this.dataFormPrize);
            } else {
                this.dataFormPrize.actionPrize = 'Edit';
                this.$emit('addData', this.dataFormPrize);
            }
            this.config.model = false;
        },

        handleClose() {
            let { configFieldNamePrize, configFieldTargetNumber } = this.configFormPrizeLP;
            configFieldNamePrize.error = false;
            configFieldTargetNumber.error = false;
            this.dataFormPrize = this.dataFormPrizeCopy;
            this.$emit('updateData', this.dataFormPrize);
            this.config.model = false;
        }
    },
    watch: {
        'config.model': {
            handler: function (newShow) {
                if (newShow) {
                    this.dataFormPrizeCopy = JSON.parse(JSON.stringify(this.dataPrize));
                    this.isLoading = true;
                    setTimeout(() => {
                        this.isShowEditor = true;
                        this.isLoading = false;
                    }, 200);
                } else {
                    this.isShowEditor = false;
                }
            },
            immediate: true,
            deep: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
