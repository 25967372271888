<template>
    <div>
        <div class="col-lg-12">
            <form class="needs-validation" autocomplete="off">
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                        >{{ $t('landingPage.settingTargetProduct.titleTargetProduct') }} <span class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="dataForm.title_target__product"
                            :config.sync="configForm.configFieldTitleTargetProduct"
                            @blur="handleValidateField(configForm.configFieldTitleTargetProduct)"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{
                        $t('landingPage.settingTargetProduct.detailTargetProduct')
                    }}</label>
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="dataForm.detail_target_product"
                            :config.sync="configForm.configFieldDetailTargetProduct"
                            @blur="handleValidateField(configForm.configFieldDetailTargetProduct)"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                        >{{ $t('landingPage.settingTargetProduct.descriptionTargetProduct') }}
                    </label>
                    <div class="col-sm-9">
                        <!-- <Editor
                            :id="`description_target_product`"
                            :model.sync="dataForm.description_target_product"
                            :modelEvents="`change keydown blur focus paste`"
                            :configInit="configEditor"
                            @init="onEditorInit"
                        /> -->
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                        >{{ $t('landingPage.settingTargetProduct.imageTargetProduct') }}
                    </label>
                    <div class="col-sm-9">
                        <UploadFileCommon @dataFile="handleFileImageTargetProduct" title="ファイルアップロード" :id="`ImageTargetProduct`" />
                        <div v-if="dataForm.image_target_product?.origin_name" class="d-flex align-items-center my-2">
                            <a
                                target="_blank"
                                :href="dataForm.image_target_product?.path"
                                @click.prevent="downLoadFile(dataForm.image_target_product?.path, dataForm.image_target_product?.origin_name)"
                                >{{ dataForm.image_target_product?.origin_name }}</a
                            >
                            <span class="ml-2" style="cursor: pointer; font-size: 20px" @click="dataForm.image_target_product = null"
                                ><i class="ri-close-line" style="vertical-align: bottom"></i
                            ></span>
                        </div>

                        <b-img
                            v-if="dataForm.image_target_product?.path"
                            class=""
                            thumbnail
                            fluid
                            :src="dataForm.image_target_product?.path"
                            :alt="dataForm.image_target_product.origin_name"
                            rounded
                            style="width: 150px; height: 150px; object-fit: cover; object-position: center"
                        ></b-img>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{
                        $t('landingPage.settingTargetProduct.linkTargetProduct')
                    }}</label>
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="dataForm.link_target_product"
                            :config.sync="configForm.configFieldLinkTargetProduct"
                            @blur="handleValidateField(configForm.configFieldLinkTargetProduct)"
                        />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { InputText } from '@/components/Input';
import { validateField } from '@/utils/validate';
import { handleDownFile } from '@/utils/format';

// import Editor from '@/components/Editor/main.vue';
import UploadFileCommon from '@/components/Uploads/Files/main.vue';

export default {
    name: 'settingTargetProduct',
    components: {
        InputText,
        // Editor,
        UploadFileCommon
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        },
        configForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {
            objEditor: null,
            configEditor: {
                content_style: 'p { margin: 0 }',
                height: 600,
                menubar: true,
                plugins: [
                    'hr advlist autolink lists link image charmap',
                    'searchreplace visualblocks code fullscreen',
                    'print preview anchor insertdatetime media template',
                    'paste code help wordcount table code lists imagetools '
                ],
                toolbar:
                    'myCustomToolbarButton hr undo redo | formatselect | bold italic | \
                                          alignleft aligncenter alignright | \
                                          link image media | \
                                          bullist numlist outdent indent | help code\
                                          table tableinsertdialog tablecellprops tableprops | fullscreen ',
                table_toolbar:
                    'tableprops tabledelete tablemergecells tablesplitcells | tableinsertrowbefore tableinsertrowafter tablerowprops tabledeleterow | tableinsertcolbefore tableinsertcolafter tablecellprops tabledeletecol'
            }
        };
    },
    methods: {
        handleValidateField(configField) {
            validateField(configField);
        },

        onEditorInit(editor) {
            this.objEditor = editor;
        },

        handleFileImageTargetProduct(file) {
            this.dataForm.image_target_product = file;
        },

        downLoadFile(path, name) {
            if (path) return handleDownFile(path, name);
        }
    }
};
</script>

<style lang="scss" scoped></style>
