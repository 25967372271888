import i18n from '@/i18n';
import { state } from '@/state/modules/authfack.js';

const campaignDefault = state?.user?.default_campaign ?? null;

const TABS = () => {
    return [
        {
            id: 1,
            name: i18n.t('landingPage.list.tabBasic'),
            disabled: false
        },
        {
            id: 2,
            name: i18n.t('landingPage.list.tabDesign'),
            disabled: false
        },
        {
            id: 3,
            name: i18n.t('landingPage.list.tabBlocks'),
            disabled: false
        },
        {
            id: 4,
            name: i18n.t('landingPage.list.tabFisrtView'),
            disabled: false
        },
        {
            id: 5,
            name: i18n.t('landingPage.list.tabTimeApp'),
            disabled: false
        },
        {
            id: 6,
            name: i18n.t('landingPage.list.tabPrizes'),
            disabled: false
        },
        {
            id: 7,
            name: i18n.t('landingPage.list.tabTargetProduct'),
            disabled: false
        },
        {
            id: 8,
            name: i18n.t('landingPage.list.tabMethodsApp'),
            disabled: false
        },
        {
            id: 9,
            name: i18n.t('landingPage.list.tabReceiptPhotography'),
            disabled: false
        },
        {
            id: 10,
            name: i18n.t('landingPage.list.tabRuleApp'),
            disabled: false
        },
        {
            id: 11,
            name: i18n.t('landingPage.list.tabShareBlock'),
            disabled: false
        },
        {
            id: 12,
            name: i18n.t('landingPage.list.tabContact'),
            disabled: false
        },
        {
            id: 13,
            name: i18n.t('landingPage.list.tabButton'),
            disabled: false
        },
        {
            id: 14,
            name: i18n.t('landingPage.list.tabBlockAdd'),
            disabled: false
        }
        // {
        //     id: 16,
        //     name: 'ボタンブロックでの公開前・公開後切り替え - Chuyển đổi nút',
        //     disabled: false
        // },
        // {
        //     id: 17,
        //     name: 'LPの公開前後でのグレーアウト - Màu xám',
        //     disabled: false
        // },
        // {
        //     id: 18,
        //     name: 'ボタンブロックでの公開前・公開後切り替え - Xác thực cơ bản',
        //     disabled: false
        // }
    ];
};

const SOCIAL_MEDIA_SHARE = [
    {
        id: 1,
        name: 'Facebook',
        key: 'facebook',
        active: false,
        backgroundColor: '#1877f2',
        urlShare: 'https://www.facebook.com/sharer/sharer.php?u=',
        logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Facebook_Logo_%282019%29.png/1200px-Facebook_Logo_%282019%29.png'
    },
    {
        id: 2,
        name: 'LINE',
        key: 'line',
        active: false,
        backgroundColor: '#14bd14',
        urlShare: 'https://social-plugins.line.me/lineit/share?url=',
        logo: 'https://static.vecteezy.com/system/resources/previews/023/986/611/non_2x/line-app-logo-line-app-logo-transparent-line-app-icon-transparent-free-free-png.png'
    },
    {
        id: 3,
        name: 'X',
        key: 'x',
        active: false,
        backgroundColor: '#000000',
        urlShare: 'https://twitter.com/intent/tweet?url=',
        logo: 'https://static.vecteezy.com/system/resources/previews/042/148/611/non_2x/new-twitter-x-logo-twitter-icon-x-social-media-icon-free-png.png'
    },
    {
        id: 4,
        name: 'Instagram',
        key: 'instagram',
        active: false,
        backgroundColor: '#ed5128',
        urlShare: 'https://www.instagram.com/?url=',
        logo: 'https://static.vecteezy.com/system/resources/previews/018/930/415/non_2x/instagram-logo-instagram-icon-transparent-free-png.png'
    },
    // {
    //     id: 5,
    //     name: 'Tiktok',
    //     key: 'tiktok',
    //     active: false,
    //     backgroundColor: '#000',
    //     urlShare: 'https://www.tiktok.com/share?url=',
    //     logo: 'https://static.vecteezy.com/system/resources/previews/016/716/450/non_2x/tiktok-icon-free-png.png'
    // },
    {
        id: 6,
        name: 'Pinterest',
        key: 'pinterest',
        active: false,
        backgroundColor: '#e6001d',
        urlShare: 'https://www.pinterest.com/pin/create/button/?url=',
        logo: 'https://static.vecteezy.com/system/resources/previews/018/930/744/non_2x/pinterest-logo-pinterest-transparent-free-png.png'
    }
];

const STATUS_SETTING_BASIC = [
    {
        id: 1,
        value: '公開'
    },
    {
        id: 2,
        value: '下書き'
    },
    {
        id: 3,
        value: '終了'
    }
];

const STATUS_SETTING_PRIZE = [
    {
        id: '0',
        value: '有効'
    },
    {
        id: 1,
        value: '無効の切り替え'
    }
];

const STATUS_FORM = [
    {
        id: 1,
        value: '公開前'
    },
    {
        id: 2,
        value: '終了後'
    },
    {
        id: 3,
        value: '下書き'
    }
];

const configs = {
    campaign: {
        trackBy: 'id',
        label: 'name',
        error: false,
        isRemove: false
    },
    nameLandingPage: {
        trackBy: 'id',
        label: 'name',
        error: false,
        isRemove: false
    },
    client: {
        trackBy: 'id',
        label: 'name',
        error: false,
        isRemove: false
    },
    configTable: {
        headers: [
            {
                label: i18n.t('landingPage.list.lpID')
            },
            {
                label: i18n.t('landingPage.list.nameCampaign')
            },
            {
                label: i18n.t('landingPage.list.nameLandingPage')
            },
            {
                label: i18n.t('landingPage.list.urlPublic')
            },
            {
                label: i18n.t('landingPage.list.status')
            },
            {
                label: i18n.t('landingPage.list.startDateLP')
            },
            {
                label: i18n.t('landingPage.list.endDateLP')
            }
        ]
    },
    configPage: {
        total: 0,
        limit: 10,
        page: 1
    }
};

const LIST_FONT_PAGE = [
    {
        id: 1,
        name: 'Yuji Mai, serif',
        url: 'https://fonts.googleapis.com/css2?family=Yuji+Mai&display=swap',
        property_style: {
            fontFamily: "'Yuji Mai', serif",
            fontWeight: 400,
            fontStyle: 'normal'
        }
    },
    {
        id: 2,
        name: 'Noto Sans JP, sans-serif',
        url: 'https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Yuji+Mai&display=swap',
        property_style: {
            fontFamily: "'Yuji Mai', serif",
            fontWeight: 400,
            fontStyle: 'normal'
        }
    },
    {
        id: 3,
        name: 'Hachi Maru Pop, cursive',
        url: 'https://fonts.googleapis.com/css2?family=Hachi+Maru+Pop&family=Noto+Sans+JP:wght@100..900&family=Yuji+Mai&display=swap',
        property_style: {
            fontFamily: "'Hachi Maru Pop', cursive",
            fontWeight: 400,
            fontStyle: 'normal'
        }
    }
];

const SQUARE_TYPE_PAGE = [
    {
        id: 1,
        value: 'スクエア'
    },
    {
        id: 2,
        value: 'サークル'
    }
];

const initFormSearch = () => {
    return {
        id: null,
        campaign_name: null,
        name: null,
        from_date: {
            campaign_start_date: '',
            campaign_end_date: '',
            lp_start_date: '',
            lp_end_date: ''
        },
        to_date: {
            campaign_start_date: '',
            campaign_end_date: '',
            lp_start_date: '',
            lp_end_date: ''
        },
        filters: {
            status: null,
            business_pic: null,
            instruction_pic: null
        }
    };
};

const initFormRegister = () => {
    return {
        //----- setting basic --------------
        campaign: campaignDefault,
        code: '',
        name: '',
        number_cp: '',
        sale_staff: '',
        person_in_charge: '',
        zac_id: '',
        sfid: '',
        campaign_entity_id: '',
        //  start date cp
        campaign_start_date: '',
        campaign_start_time: '',
        //  end date cp
        campaign_end_date: '',
        campaign_end_time: '',
        //  start date lp
        lp_start_date: '',
        lp_start_time: '',
        //  end date lp
        lp_end_date: '',
        lp_end_time: '',
        status: '',
        keywords: [],
        description: '',
        analytics: '',
        og_image: null,
        url_public: '',
        //----- end setting basic --------------

        //----- start setting design --------------
        design_settings: {
            // 1 SettingOverall
            font_page: '',
            square_type: '',
            background_color: '',
            title_color: '',
            font_color: '',
            button_color: ''
        },
        //----- end setting design --------------

        //----- start setting blocks --------------
        blocks_settings: [
            {
                order_index: 0,
                name: 'ファーストビュー',
                category: '',
                is_visibled: true,
                block_default: true,
                key: 'first_view'
            },
            // {
            //     order_index: 1,
            //     name: 'Block điền tự do',
            //     category: '',
            //     is_visibled: false,
            //     block_default: true,
            //     key: 'block_free',
            //     content_free: ''
            // },
            // {
            //     order_index: 2,
            //     name: 'Block button',
            //     category: '',
            //     is_visibled: false,
            //     block_default: true,
            //     key: 'block_button',
            //     content_free: ''
            // },
            {
                order_index: 3,
                name: '応募期間',
                category: '',
                is_visibled: true,
                block_default: true,
                key: 'application_period'
            },
            // {
            //     order_index: 4,
            //     name: 'Block điền tự do',
            //     category: '',
            //     is_visibled: false,
            //     block_default: true,
            //     key: 'block_free',
            //     content_free: ''
            // },
            // {
            //     order_index: 5,
            //     name: 'Block button',
            //     category: '',
            //     is_visibled: false,
            //     block_default: true,
            //     key: 'block_button',
            //     content_free: ''
            // },
            {
                order_index: 6,
                name: '賞品設定',
                category: '',
                is_visibled: true,
                block_default: true,
                key: 'block_prize'
            },
            // {
            //     order_index: 7,
            //     name: 'Block điền tự do',
            //     category: '',
            //     is_visibled: false,
            //     block_default: true,
            //     key: 'block_free',
            //     content_free: ''
            // },
            // {
            //     order_index: 8,
            //     name: 'Block button',
            //     category: '',
            //     is_visibled: false,
            //     block_default: true,
            //     key: 'block_button',
            //     content_free: ''
            // },
            {
                order_index: 9,
                name: '応募方法設定',
                category: '',
                is_visibled: true,
                block_default: true,
                key: 'block_application_method'
            },
            // {
            //     order_index: 10,
            //     name: 'Block điền tự do',
            //     category: '',
            //     is_visibled: false,
            //     block_default: true,
            //     key: 'block_free',
            //     content_free: ''
            // },
            // {
            //     order_index: 11,
            //     name: 'Block button',
            //     category: '',
            //     is_visibled: false,
            //     block_default: true,
            //     key: 'block_button',
            //     content_free: ''
            // },
            {
                order_index: 12,
                name: 'レシート撮影の注意事項',
                category: '',
                is_visibled: true,
                block_default: true,
                key: 'block_receipt_photography_tutorial'
            },
            // {
            //     order_index: 13,
            //     name: 'Block điền tự do',
            //     category: '',
            //     is_visibled: false,
            //     block_default: true,
            //     key: 'block_free',
            //     content_free: ''
            // },
            // {
            //     order_index: 14,
            //     name: 'Block button',
            //     category: '',
            //     is_visibled: false,
            //     block_default: true,
            //     key: 'block_button',
            //     content_free: ''
            // },
            {
                order_index: 15,
                name: '応募規約',
                category: '',
                is_visibled: true,
                block_default: true,
                key: 'block_application_rule'
            },
            // {
            //     order_index: 16,
            //     name: 'Block điền tự do',
            //     category: '',
            //     is_visibled: false,
            //     block_default: true,
            //     key: 'block_free',
            //     content_free: ''
            // },
            // {
            //     order_index: 17,
            //     name: 'Block button',
            //     category: '',
            //     is_visibled: false,
            //     block_default: true,
            //     key: 'block_button',
            //     content_free: ''
            // },
            {
                order_index: 18,
                name: 'お問い合わせ',
                category: '',
                is_visibled: true,
                block_default: true,
                key: 'block_contact'
            },
            // {
            //     order_index: 19,
            //     name: 'Block điền tự do',
            //     category: '',
            //     is_visibled: false,
            //     block_default: true,
            //     key: 'block_free',
            //     content_free: ''
            // },
            // {
            //     order_index: 20,
            //     name: 'Block button',
            //     category: '',
            //     is_visibled: false,
            //     block_default: true,
            //     key: 'block_button',
            //     content_free: ''
            // },
            {
                order_index: 21,
                name: 'シェアブロック',
                category: '',
                is_visibled: true,
                block_default: true,
                key: 'block_share'
            }
            // {
            //     order_index: 22,
            //     name: 'Block điền tự do',
            //     category: '',
            //     is_visibled: false,
            //     block_default: true,
            //     key: 'block_free',
            //     content_free: ''
            // },
            // {
            //     order_index: 23,
            //     name: 'Block button',
            //     category: '',
            //     is_visibled: false,
            //     block_default: true,
            //     key: 'block_button',
            //     content_free: ''
            // },
        ],
        //----- end setting blocks --------------

        //----- start setting firstview --------------
        first_view: {
            html_content: ''
        },
        //----- end setting firstview --------------

        //----- start setting application period  --------------
        application_period: {
            title: '応募期間',
            style: null,
            start_date: '',
            end_date: '',
            html_content: ''
        },
        //----- end setting application period  --------------

        //----- start setting prize settings  --------------
        prize_settings: {
            title: '応募期間',
            background: {
                image: '',
                color: '#ffffff'
            },
            is_repeat: false,
            style: {
                id: 1,
                name: 'A'
            },
            html_content: '',
            prizes: []
        },
        //----- end setting prize settings  --------------

        //----- start setting target product settings  --------------
        block_target_product: {
            title_block: 'Trương trình quay số trúng thưởng !',
            background_image: null,
            background_color: '',
            is_repeat: false,
            style: null,
            target_products: [],
            html_content: ''
        },
        //----- end setting target product settings  --------------

        //----- start setting application method settings  --------------
        block_application_method: {
            title_block: 'Trương trình PHƯƠNG THỨC ỨNG TUYỂN !',
            background_image: null,
            background_color: '',
            is_repeat: false,
            style: null,
            application_methods: [],
            html_content: ''
        },
        //----- end setting application method settings  --------------

        //----- start setting receipt photography settings  --------------
        block_receipt_photography_tutorial: {
            html_content: ''
        },
        //----- end setting receipt photography settings  --------------

        //----- start setting application rule settings  --------------
        block_application_rule: {
            title_block: 'Quy định ứng tuyển',
            background_image: null,
            background_color: '#ffffff',
            is_repeat: false,
            html_content: ''
        },
        //----- end setting application rule settings  --------------

        //----- start setting share settings  --------------
        block_share: {
            title_block: 'Chia sẻ block !',
            background_image: null,
            background_color: '#ffffff',
            is_repeat: false,
            url: '',
            share_text: '',
            html_content: '',
            hashtag: [],
            platform: {
                facebook: false,
                line: false,
                x: false,
                instagram: false,
                tiktok: false,
                pinterest: false
            }
        },
        //----- end setting share settings  --------------

        //----- start setting contact settings  --------------
        block_contact: {
            title_block: 'Khối liên hệ !',
            background_image: null,
            background_color: '#ffffff',
            is_repeat: false,
            style: null,
            contact_secretariat_name: '',
            tel: '',
            mail: '',
            html_content: ''
        },
        //----- end setting contact settings  --------------

        //----- start setting free blocks settings  --------------
        free_blocks: [],
        //----- start setting free blocks settings  --------------

        //----- start setting button blocks settings  --------------
        button_blocks: []
        //----- start setting button blocks settings  --------------
    };
};

export {
    TABS,
    STATUS_FORM,
    configs,
    LIST_FONT_PAGE,
    SQUARE_TYPE_PAGE,
    STATUS_SETTING_BASIC,
    STATUS_SETTING_PRIZE,
    SOCIAL_MEDIA_SHARE,
    initFormSearch,
    initFormRegister
};
