<template>
    <div>
        <ModalCommon :id="id" :config="configModalTypeTitle" @save="handleSaveTypeTitle()" @close="handleCloseTypeTitle()">
            <form class="needs-validation" autocomplete="off">
                <div class="form-group row">
                    <label class="col-sm-3 mw--form" for="prizeLessStatus">{{ this.$t('pageCampaign.tabPrize.type') }}</label>
                    <div class="col-sm-9">
                        <Multiselect
                            :id="`select-prize-type-title`"
                            :value.sync="formDataType.master_id"
                            :options="masterTypes"
                            :config.sync="formDataType.configFieldSelectTypeTitle"
                            @select="handleSelectType()"
                            @remove="handleRemoveType()"
                        ></Multiselect>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 mw--form" for="prizeLessStatus">{{ formDataType?.master_id?.value ?? '' }}</label>
                    <div class="col-sm-9">
                        <div v-for="(item, idx) in masterTypes" :key="idx">
                            <div v-if="renderListLv1(item, formDataType?.master_id?.id).check" class="d-flex flex-wrap">
                                <div v-for="(item, idx) in renderListLv1(item, formDataType?.master_id?.id).itemLv1" :key="idx">
                                    <InputCheckBox
                                        class="mr-3 mb-2"
                                        :model.sync="formDataType.listCheckedLv1"
                                        :id="`check-master_id-type${item.id}`"
                                        :value="item.id"
                                        :label="item.value"
                                        @change="handleCheckBoxLv1(item, formDataType)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-for="(item, idx) in masterTypes" :key="idx">
                    <div v-if="formDataType?.master_id?.value == 'デジタル' && item.value == 'デジタル'">
                        <div v-for="(ite, idx) in item.data" :key="idx">
                            <div v-if="formDataType.listCheckedLv1.includes(ite.id) && ite.data.length" class="form-group row">
                                <label class="col-sm-3 mw--form" for="prizeLessStatus">{{ ite?.value ?? '' }}</label>
                                <div class="col-sm-9">
                                    <div class="d-flex flex-wrap">
                                        <div v-for="(it, idx) in ite.data" :key="idx">
                                            <InputCheckBox
                                                class="mr-3 mb-2"
                                                :model.sync="formDataType.listCheckedLv2"
                                                :id="`check-id-type_lv2${it.id}`"
                                                :value="it.id"
                                                :label="it.value"
                                                @change="handleChangeCheckBoxLV2(it, formDataType)"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-for="(check1, idx) in formDataType.children_data" :key="`check1-${idx}`">
                            <div v-for="(check2, idxe) in check1.children_data" :key="`check2-${idxe}`">
                                <div v-if="formDataType.listCheckedLv2.includes(check2.master_id?.id || check2.master_id)" class="form-group row">
                                    <label class="col-sm-3">{{ check2?.master_id?.value }}</label>
                                    <div class="col-sm-9">
                                        <InputText
                                            :id="`electronic_money_point_info`"
                                            :model.sync="check2.note.electronic_money_point_info"
                                            :config.sync="check2.note.configFieldElectronic"
                                            @blur="handleValidateField(check2.note.configFieldElectronic)"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="formDataType?.master_id?.id" class="form-group row">
                    <label class="col-sm-3 mw--form" for="prizeLessStatus">{{ $t('pageCampaign.tabPrize.typeTitle.over500') }}</label>
                    <div class="col-sm-9">
                        <InputCheckBox
                            class="mr-3 mb-2"
                            :model.sync="hasHighCouponValue"
                            :id="`check-${formDataType?.master_id?.value ?? ''}`"
                            :value="false"
                            :label="''"
                        />
                    </div>
                </div>
            </form>
        </ModalCommon>
    </div>
</template>

<script>
import { initObjectTypeTitle } from '@/components/Campaign/TabPrize/index';
import { InputText, InputCheckBox } from '@/components/Input';
import { CONFIG_MODALTYPETITLE } from '../../constans';
import { validateField, validateAllField } from '@/utils/validate';

import Multiselect from '@/components/Multiselect/main.vue';
import ModalCommon from '@/components/Modal/main.vue';

export default {
    name: 'ModalTypeTitle',
    components: {
        ModalCommon,
        InputText,
        InputCheckBox,
        Multiselect
    },
    props: {
        id: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        isShow: {
            type: Boolean,
            default: false
        },
        isHighCouponValue: {
            type: Boolean,
            default: false
        },
        dataTypes: {
            type: Object,
            default: () => {
                return {};
            }
        },
        masterTypes: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            hasHighCouponValue: this.isHighCouponValue,
            hasHighCouponValueCopy: JSON.parse(JSON.stringify(this.isHighCouponValue)),
            formDataType: this.dataTypes,
            formDataTypeCopy: JSON.parse(JSON.stringify(this.dataTypes)),
            configModalTypeTitle: CONFIG_MODALTYPETITLE
        };
    },

    methods: {
        handleSaveTypeTitle() {
            let checkValidate = validateAllField(this.formDataType);
            if (!checkValidate) return;

            this.$emit('update:dataTypes', this.formDataType);
            this.$emit('update:isShow', false);
            this.$emit('update:isHighCouponValue', this.hasHighCouponValue);
        },

        handleCloseTypeTitle() {
            this.formDataType = this.formDataTypeCopy;
            this.hasHighCouponValue = this.hasHighCouponValueCopy;

            this.$emit('update:dataTypes', this.formDataType);
            this.$emit('update:isShow', false);
            this.$emit('update:isHighCouponValue', this.hasHighCouponValue);
        },

        handleSelectType() {
            this.formDataType = initObjectTypeTitle();
            this.hasHighCouponValue = false;
        },

        handleRemoveType() {
            this.formDataType = initObjectTypeTitle();
            this.hasHighCouponValue = false;
        },

        handleCheckBoxLv1(obj1, form) {
            if (form.listCheckedLv1.includes(obj1.id)) {
                form.children_data.push(initObjectTypeTitle(obj1, 'change'));
            } else {
                for (const targetChild of obj1.data) {
                    form.listCheckedLv2 = form.listCheckedLv2.filter((checked2) => checked2 !== targetChild.id);
                }
                form.children_data = form.children_data.filter((itemTarget) => itemTarget.master_id !== obj1.id);
            }
        },

        handleChangeCheckBoxLV2(obj2, form) {
            const found2 = form.children_data.find((ot) => ot.master_id == obj2.parent_id || ot.master_id.id == obj2.parent_id);
            if (form.listCheckedLv2.includes(obj2.id)) {
                found2.children_data.push(initObjectTypeTitle(obj2, 'change'));
            } else {
                found2.children_data = found2.children_data.filter((itemTarget) => {
                    let condition = itemTarget.master_id?.id ?? itemTarget.master_id ?? null;
                    return condition !== obj2.id;
                });
            }
        },

        renderListLv1(idOrigin, checkIdLv1) {
            return {
                check: idOrigin.id === checkIdLv1,
                itemLv1: idOrigin.data
            };
        },

        handleValidateField(configField) {
            validateField(configField);
        }
    },
    watch: {
        title: {
            handler: function (valTitle) {
                this.configModalTypeTitle.title = valTitle;
            },
            immediate: true
        },
        isShow: {
            handler: function (valShow) {
                this.configModalTypeTitle.model = valShow;
                if (valShow) {
                    this.formDataTypeCopy = JSON.parse(JSON.stringify(this.dataTypes));
                    this.hasHighCouponValueCopy = JSON.parse(JSON.stringify(this.isHighCouponValue));
                }
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
