<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import {
    masterComputed,
    authComputed,
    masterMethods,
    faqMethods,
    partnersMethods,
    campaignMethods,
    clientMethods,
    checkPermission,
    mapDataCampaigns,
    employeeMethods,
    prizeMethods
} from '@/state/helpers';
import { maxLength, required, url } from 'vuelidate/lib/validators';
import { campaignStatus, formCampaign, listContactInformations, numberOfApplications, numberOfInquiries } from '@/config/var-common';
import { showMessage } from '@/utils/messages';
import { handleValidateInformationBasic } from '@/components/Campaign/InformationBasic/validate.js';
import { handleValidateArrayNameAndQuantity } from '@/components/Campaign/TabPrize/index.js';
import { handleDataDetail, handleMapDate } from './index';
import { validateAllField } from '@/utils/validate';

import ModalClient from '@/components/Campaign/TabClients/modal-client.vue';
import Tab7 from '@/components/Campaign/Tab7/main.vue';
import InformationBasic from '@/components/Campaign/InformationBasic/main.vue';
import TabClients from '@/components/Campaign/TabClients/main.vue';
import TabPrizes from '@/components/Campaign/TabPrize/main.vue';
import TabTools from '@/components/Campaign/TabTool/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';

export default {
    page: {
        title: 'キャンペーン 編集',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        TabClients,
        InformationBasic,
        Tab7,
        TabPrizes,
        TabTools,
        ModalClient,
        Layout,
        Footer,
        Multiselect
    },
    data() {
        return {
            hashTag: {
                arrayTag: [],
                placeholder: ''
            },
            personInChargeObject: null,
            saleStaffObject: {},
            sns_application_condition_1: {},
            purchase_conditions: {},
            contact_informations: {},
            progressStatus: null,
            listItemPersons: [
                {
                    id: '',
                    name: '',
                    team: ''
                },
                {
                    id: '',
                    name: '',
                    team: ''
                }
            ],
            listProjectManagement: [],
            configProjectManagement: {
                error: false,
                errorText: '',
                isRemove: false,
                loading: false,
                trackBy: 'id',
                label: 'name'
            },
            listCategories: [],
            listCategoriesSub: [],
            campaignStatus: campaignStatus,
            loadingRegister: false,
            loading: false,
            configKeyword: {
                error: false,
                arrayTag: []
            },
            form: formCampaign,
            listContactInformations: listContactInformations,
            listDataMasterCampaignType: [],
            listDataMasterCampaignTypeChild: [],
            listDataMasterSaleStaf: [],
            listDataMasterSaleEmploy: [],
            listDataMasterInstructorStaf: [],
            listDataMasterClients: [],
            listDataMasterConditionApplySNS: [],
            listDataMasterConditionPurchase1: [],
            listDataMasterCategoryProductTarget: [],
            submitted: false,
            listDataMasterCampaignProject: [],
            listDataCustomerTeamPic: [],
            subDataCustomerTeamPic: [null],
            phonePic: null,
            customerTeamPic: null,
            arrayObjectPizes: [],
            activeTabEdit: 0,
            listDataMasterOrderScope: [],
            tableScopeOrder: [],
            isBookmarkCampaign: false,
            hasLoadingBookmark: false,
            listIPInformationItem: [],
            objScopeOrder: null,
            listMasterSponsorInformation: [],
            listnumberOfInquiries: [],
            listEmployeeDepartment: [],
            listCampaignSocialPlatform: [],
            listCustomerTeam: [],
            listTypeTitle: []
        };
    },
    watch: {
        sourceData(newValue) {
            if (newValue) this.form.source_data.id = newValue.id;
            else this.form.source_data.id = '';
        },
        sns_application_condition_1: function (newName) {
            this.selectObject(newName, 'sns_application_condition_1', 'id');
        },
        personInChargeObject: function (newName) {
            this.form.person_in_charge = newName
                ? {
                      id: newName?.id ?? null
                  }
                : null;
        },
        saleStaffObject: function (newName) {
            this.selectObject(newName, 'sale_staff', 'id');
        },

        'hashTag.arrayTag': function (newOld) {
            this.form.hashtag = newOld;
        },

        phonePic: function (newOld) {
            this.form.phone_pic = {
                id: newOld?.id ? newOld?.id : ''
            };
        },
        customerTeamPic: function (newOld) {
            this.form.customer_team_pic = {
                id: newOld?.id ? newOld?.id : ''
            };
        }
    },
    computed: {
        ...masterComputed,
        ...authComputed,

        filteredOptions() {
            return (index) => {
                const selectedValues = this.listItemPersons.filter((value, i) => i !== index);
                let arr = JSON.parse(JSON.stringify(this.listDataMasterInstructorStaf));
                arr.forEach((option, idx) => {
                    let i = selectedValues.findIndex((x) => x?.id === option?.id || x?.id === this.personInChargeObject?.id);
                    if (i >= 0 || option?.id === this.personInChargeObject?.id) {
                        arr[idx].$isDisabled = true;
                    } else {
                        arr[idx].$isDisabled = false;
                    }
                });
                return arr;
            };
        },
        filteredOptions1() {
            return (index) => {
                const selectedValues = this.form.part_time_employees.filter((value, i) => i !== index);
                let arr = JSON.parse(JSON.stringify(this.listDataMasterSaleStaf));
                arr.forEach((option, idx) => {
                    let i = selectedValues.findIndex((x) => x.id === option.id);
                    if (i >= 0) {
                        arr[idx].$isDisabled = true;
                    } else {
                        arr[idx].$isDisabled = false;
                    }
                });
                return arr;
            };
        },

        OPTIONCUSTOMER() {
            return (index) => {
                const selectedValues = this.subDataCustomerTeamPic.filter((value, i) => i !== index);
                let arr = JSON.parse(JSON.stringify(this.listDataCustomerTeamPic));
                arr.forEach((option, idx) => {
                    let i = selectedValues.findIndex((x) => x?.id === option?.id || x?.id === this.customerTeamPic?.id);
                    if (i >= 0 || option?.id === this.customerTeamPic?.id) {
                        arr[idx].$isDisabled = true;
                    } else {
                        arr[idx].$isDisabled = false;
                    }
                });
                return arr;
            };
        }
    },
    validations: {
        listCategoriesSub: {
            $each: {
                extension: {
                    offical_name_sns: { maxLength: maxLength(255) },
                    offical_id_sns: { maxLength: maxLength(255) },
                    number_of_circulation: { maxLength: maxLength(255) },
                    company_information: { maxLength: maxLength(255) }
                }
            }
        },
        form: {
            name: { required, maxLength: maxLength(255) },
            application_date: { maxLength: maxLength(255) },
            purchase_datetime: { maxLength: maxLength(255) },
            inform: { maxLength: maxLength(255) },
            application_form: { maxLength: maxLength(255) },
            prize_shipping_period: { maxLength: maxLength(255) },
            inquiry_office: { maxLength: maxLength(255) },
            url: { url },
            chatwork_rooms: {
                $each: {
                    room_name: { maxLength: maxLength(255) }
                }
            },
            zac_id: { maxLength: maxLength(255) },
            sfid: { maxLength: maxLength(255) },
            campaign_entity_id: { maxLength: maxLength(255) },
            folder_project: { maxLength: maxLength(255) },
            folder_quotation: { maxLength: maxLength(255) }
        }
    },

    async mounted() {
        this.userInfo = JSON.parse(localStorage.getItem('user'));
        await this.callDataFromApis();
        this.getDetail(this.$route.query.id);
    },
    methods: {
        checkPermission,
        ...masterMethods,
        ...faqMethods,
        ...partnersMethods,
        ...campaignMethods,
        ...clientMethods,
        ...employeeMethods,
        ...prizeMethods,

        async callDataFromApis() {
            this.loading = true;
            try {
                await Promise.allSettled([
                    this.getListMaterCommon(),
                    this.getListCommonUser(),
                    this.handleGetMasterOrderScope(),
                    this.getListMasterClients()
                ]);
            } catch (error) {
                console.log('error', error);
            } finally {
                this.loading = false;
            }
        },

        async getListMaterCommon() {
            try {
                const dataRes = await this.listMaster('campaign/common-campaign-data');
                this.listDataMasterCampaignProject = dataRes['campaign.project_details'];
                this.listDataMasterConditionPurchase1 = dataRes['campaign.purchase_condition'];
                this.listDataMasterConditionApplySNS = dataRes['campaign.conditions_applying_sns'];
                this.listIPInformationItem = dataRes['campaign.information_ip'];
                this.listEmployeeDepartment = dataRes['employee.department'];
                this.listDataMasterCampaignType = dataRes['campaign.type'];
                this.listMasterSponsorInformation = dataRes['campaign.sponsor_information'];
                this.listDataMasterCategoryProductTarget = dataRes['campaign.product_target'];
                this.listCampaignSocialPlatform = dataRes['campaign.social_platform'];
                this.listTypeTitle = dataRes['event_prize.type'];
            } catch (error) {
                console.log('error', error);
            }
        },

        async getListCommonUser() {
            try {
                const dataRes = await this.listMaster('campaign/common-user');
                this.listDataMasterSaleStaf = dataRes['parttime']; // 4
                this.listUserFilterRole = dataRes['admin_and_instructor']; //1,3
                this.listDataMasterSaleEmploy = dataRes['sale']; //2
                this.listCustomerTeam = dataRes['customer_team'];
                this.getListMasterInstructorStaf(this.listCustomerTeam, this.listUserFilterRole);
            } catch (error) {
                console.log('error', error);
            }
        },

        selectObject(data, key, key1) {
            if (data) {
                if (key1) this.form[key][key1] = data.id;
                else this.form[key] = data.id;
            } else {
                if (key1) this.form[key][key1] = '';
                else this.form[key] = '';
            }
        },

        changeData() {
            let index = this.listItemPersons.findIndex((x) => x?.id === this.personInChargeObject?.id);
            if (index >= 0) {
                this.listItemPersons[index].id = '';
                this.listItemPersons[index].name = '';
                this.listItemPersons[index].team = '';
                this.filteredOptions(index);
            }
        },
        changeDataCustomer() {
            let index = this.subDataCustomerTeamPic.findIndex((x) => x?.id === this.customerTeamPic?.id);
            if (index >= 0) {
                this.subDataCustomerTeamPic[index] = null;
                this.OPTIONCUSTOMER(index);
            }
        },

        async getDetail(id) {
            this.loading = true;
            try {
                const data = await this.detailCampaign(id);
                const resultDetail = handleDataDetail(
                    data,
                    this.campaignStatus,
                    numberOfApplications,
                    numberOfInquiries,
                    this.sns_application_condition_1,
                    this.application_condition_1,
                    this.application_condition_2,
                    this.isBookmarkCampaign,
                    this.currentUser,
                    this.listContactInformations,
                    this.contact_informations,
                    this.listDataMasterCategoryProductTarget,
                    this.listDataMasterConditionPurchase1,
                    this.purchase_conditions,
                    this.saleStaffObject,
                    this.personInChargeObject,
                    this.listItemPersons,
                    this.progressStatus,
                    this.form,
                    this.phonePic,
                    this.customerTeamPic,
                    this.configKeyword,
                    this.hashTag,
                    this.listCategories,
                    this.listDataMasterCampaignTypeChild,
                    this.listCategoriesSub,
                    this.subDataCustomerTeamPic,
                    this.listDataMasterCampaignType
                );
                this.form = resultDetail.form;
                this.sns_application_condition_1 = resultDetail.sns_application_condition_1;
                this.application_condition_1 = resultDetail.application_condition_1;
                this.application_condition_2 = resultDetail.application_condition_2;
                this.isBookmarkCampaign = resultDetail.isBookmarkCampaign;
                this.contact_informations = resultDetail.contact_informations;
                this.saleStaffObject = resultDetail.saleStaffObject;
                this.personInChargeObject = resultDetail.personInChargeObject;
                this.listItemPersons = resultDetail.listItemPersons;
                this.progressStatus = resultDetail.progressStatus;
                this.phonePic = resultDetail.phonePic;
                this.customerTeamPic = resultDetail.customerTeamPic;
                this.configKeyword.arrayTag = resultDetail.configKeyword.arrayTag;
                this.hashTag.arrayTag = resultDetail.hashTag.arrayTag;
                this.listCategories = resultDetail.listCategories;
                this.listCategoriesSub = resultDetail.listCategoriesSub;
                this.listDataMasterCampaignTypeChild = resultDetail.listDataMasterCampaignTypeChild;
                this.subDataCustomerTeamPic = resultDetail.subDataCustomerTeamPic;
            } catch (error) {
                console.log('error', error);
            } finally {
                this.loading = false;
            }
        },

        getListMasterInstructorStaf(listCustomerTeam, listUserFilterRole) {
            let arrTwoInstructorStaf = [...listUserFilterRole, ...listCustomerTeam];
            this.listProjectManagement = listUserFilterRole;
            this.listDataCustomerTeamPic = listCustomerTeam;
            this.listDataMasterInstructorStaf = arrTwoInstructorStaf.reduce((acc, current) => {
                const x = acc.find((item) => item.id === current.id);
                if (!x) {
                    acc.push(current);
                }
                return acc;
            }, []);
        },

        async getListMasterClients() {
            try {
                const data = await this.listMaster('all/clients');
                this.listDataMasterClients = data;
            } catch (error) {
                console.log('error', error);
            }
        },

        formSubmit() {
            this.submitted = true;
            this.$v.$touch();
            this.form = Object.assign({}, this.form);
            let selectedTabPrize = 0;
            let checkTabInfomationBasic = handleValidateInformationBasic(
                this.form,
                this.form.configCampaignStartDateTime,
                this.form.configCampaignEndDateTime,
                this.form.configSecretariatStartDateTime,
                this.form.configSecretariatEndDateTime,
                this.form.configUrlPublishTimeFrom,
                this.form.configUrlPublishTimeTo,
                this.form.configReceiptExpirationDate,
                this.contact_informations
            );

            let errorTabPrize = handleValidateArrayNameAndQuantity(this.arrayObjectPizes);
            if (!checkTabInfomationBasic && this.activeTabEdit !== selectedTabPrize) {
                showMessage(8080, this.$bvToast, '基本情報のタブを編集してください。');
            }
            if (this.arrayObjectPizes.length > 0) {
                selectedTabPrize = 4;
                if (!errorTabPrize && this.activeTabEdit !== selectedTabPrize) {
                    showMessage(8080, this.$bvToast, '賞品情報のタブを編集してください。');
                }
            }

            let checkTabClients = validateAllField(this.form);
            if (this.form.clients.length > 0) {
                selectedTabPrize = 2;
                if (!checkTabClients && this.activeTabEdit !== selectedTabPrize) {
                    showMessage(8080, this.$bvToast, 'お客様情報のタブを編集してください。');
                }
            }

            if (
                this.$v.form.$invalid ||
                this.$v.listCategoriesSub.$each.$invalid ||
                this.configKeyword.error ||
                !checkTabInfomationBasic ||
                !errorTabPrize ||
                !checkTabClients
            ) {
                return;
            } else {
                this.register();
            }
        },

        register() {
            if (this.loadingRegister) return;
            this.loadingRegister = true;
            this.loading = true;
            if (this.form.name) {
                this.form.chatwork_rooms = this.form.chatwork_rooms.filter((item) => {
                    return item.room_id && item.room_name;
                });
                let objectData = mapDataCampaigns(
                    this.form,
                    this.configKeyword,
                    this.listItemPersons,
                    this.purchase_conditions,
                    this.contact_informations,
                    this.listCategoriesSub,
                    this.subDataCustomerTeamPic,
                    this.arrayObjectPizes,
                    this.tableScopeOrder,
                    this.listnumberOfInquiries
                );

                this.updateCampaign(objectData).then((data) => {
                    if (data.code == 200) {
                        showMessage(data.code, this.$bvToast, 'キャンペーンが更新されました。');
                        this.getDetail(this.$route.query.id);
                    } else {
                        showMessage(data.code, this.$bvToast, this.$t(data.message));
                        this.form = handleMapDate(this.form, this.form);
                        this.loading = false;
                    }

                    this.loadingRegister = false;
                });
            }
        },

        addEmploy() {
            this.form.part_time_employees.push({
                id: ''
            });
        },
        removeEmploy(k) {
            this.form.part_time_employees.splice(k, 1);
        },

        async handleGetMasterOrderScope() {
            try {
                const resultMasterOrder = await this.getMasterOrderScope();
                if (resultMasterOrder.code !== 200) return;
                this.listDataMasterOrderScope = resultMasterOrder.data;
            } catch (error) {
                console.log(error);
            }
        },

        handleEmitSaveScopeOrder(newScopeOrder) {
            this.objScopeOrder = newScopeOrder;
        },

        handleGetDetail() {
            this.$router.push({
                path: '/campaign/form/view',
                query: {
                    id: this.$route.query.id
                }
            });
        },

        async handleSetBookmarkCampaign() {
            if (this.hasLoadingBookmark) return;
            this.hasLoadingBookmark = true;
            this.isBookmarkCampaign = true;
            try {
                this.userInfo.campaign_bookmarks.push({ id: this.form.id, name: this.form.name });
                localStorage.setItem('user', JSON.stringify(this.userInfo));
                await this.setBookmarkCampaign(this.form.id);
            } catch (error) {
                console.log(error);
            } finally {
                this.hasLoadingBookmark = false;
            }
        },
        async handleUnsetBookmarkCampaign() {
            if (this.hasLoadingBookmark) return;
            this.hasLoadingBookmark = true;
            this.isBookmarkCampaign = false;
            try {
                this.userInfo.campaign_bookmarks = this.userInfo.campaign_bookmarks.filter((item) => item.id !== this.form.id);
                localStorage.setItem('user', JSON.stringify(this.userInfo));
                await this.unsetBookmarkCampaign(this.form.id);
            } catch (error) {
                console.log(error);
            } finally {
                this.hasLoadingBookmark = false;
            }
        }
    }
};
</script>

<template>
    <Layout>
        <div v-if="!loading">
            <div class="col-lg-12">
                <div class="card mb-0">
                    <div class="card-header">
                        <h5>{{ form.name }}</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 custom--tabss">
                                <form class="needs-validation" @submit.prevent="formSubmit" autocomplete="off">
                                    <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted" v-model="activeTabEdit">
                                        <b-tab active class="border-0">
                                            <template v-slot:title>
                                                <span class="d-inline-block d-sm-none">
                                                    <i class="fas fa-home"></i>
                                                </span>
                                                <span class="">基本情報</span>
                                            </template>
                                            <InformationBasic
                                                :listDataMasterCampaignType="listDataMasterCampaignType"
                                                :listDataMasterCampaignProject="listDataMasterCampaignProject"
                                                :listCategoriesS.sync="listCategories"
                                                :listDataMasterCampaignTypeChilds.sync="listDataMasterCampaignTypeChild"
                                                :listItemPersons.sync="listItemPersons"
                                                :contact_informations.sync="contact_informations"
                                                :listContactInformations="listContactInformations"
                                                :hashTag.sync="hashTag"
                                                :configKeyword.sync="configKeyword"
                                                :listCategoriesSubs.sync="listCategoriesSub"
                                                :progressStatusS.sync="progressStatus"
                                                :campaignStatus="campaignStatus"
                                                :submitted.sync="submitted"
                                                :validate.sync="$v"
                                                :data-object.sync="form"
                                                :purchase_conditions.sync="purchase_conditions"
                                                :listDataMasterConditionPurchase1="listDataMasterConditionPurchase1"
                                                :listDataMasterCategoryProductTarget="listDataMasterCategoryProductTarget"
                                                :listDataMasterConditionApplySNS="listDataMasterConditionApplySNS"
                                                :snsApplicationCondition1.sync="sns_application_condition_1"
                                                :listDataMasterOrderScope="listDataMasterOrderScope"
                                                :listIPInformationItem="listIPInformationItem"
                                                :listSponsorInformation="listMasterSponsorInformation"
                                                @saveScopeOrder="handleEmitSaveScopeOrder"
                                            ></InformationBasic>
                                        </b-tab>

                                        <b-tab>
                                            <template v-slot:title>
                                                <span class="d-inline-block d-sm-none">
                                                    <i class="far fa-envelope"></i>
                                                </span>
                                                <span class="d-none d-sm-inline-block">受注範囲＆パートナー＆ツール</span>
                                            </template>
                                            <div>
                                                <TabTools
                                                    :dataForm="form"
                                                    :dataTabTools.sync="tableScopeOrder"
                                                    :objScopeOrder="objScopeOrder"
                                                    :dataMasterOrderScope="listDataMasterOrderScope"
                                                />
                                            </div>
                                        </b-tab>

                                        <b-tab>
                                            <template v-slot:title>
                                                <span class="d-inline-block d-sm-none">
                                                    <i class="far fa-user"></i>
                                                </span>
                                                <span class="d-none d-sm-inline-block">お客様情報</span>
                                            </template>
                                            <TabClients
                                                :listDataMasterClientsS.sync="listDataMasterClients"
                                                :submitted.sync="submitted"
                                                :validate.sync="$v"
                                                :data-object.sync="form"
                                            ></TabClients>
                                        </b-tab>

                                        <b-tab>
                                            <template v-slot:title>
                                                <span class="d-inline-block d-sm-none">
                                                    <i class="far fa-envelope"></i>
                                                </span>
                                                <span class="d-none d-sm-inline-block">事務局担当情報</span>
                                            </template>
                                            <div style="height: 60vh">
                                                <div class="form-group row">
                                                    <label class="col-sm-3">営業担当</label>
                                                    <div class="col-sm-8">
                                                        <Multiselect
                                                            :id="`select-sale-staff-object`"
                                                            :value.sync="saleStaffObject"
                                                            :options="listDataMasterSaleEmploy"
                                                            :config="{
                                                                trackBy: 'id',
                                                                label: 'name',
                                                                error: false,
                                                                isRemove: false
                                                            }"
                                                        />
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label class="col-sm-3">ディレクション担当</label>
                                                    <div class="col-sm-8">
                                                        <div class="row">
                                                            <div class="col-sm-8">
                                                                <div class="d-flex align-items-center">
                                                                    <span style="width: 60px; min-width: fit-content">メイン</span>
                                                                    <div class="col-sm-12">
                                                                        <Multiselect
                                                                            :id="`select-person-in-charge-object`"
                                                                            :value.sync="personInChargeObject"
                                                                            :options="listDataMasterInstructorStaf"
                                                                            :config="{
                                                                                trackBy: 'id',
                                                                                label: 'name',
                                                                                error: false,
                                                                                isRemove: false
                                                                            }"
                                                                            @select="changeData()"
                                                                        />
                                                                    </div>

                                                                    <span style="min-width: fit-content">{{
                                                                        personInChargeObject?.role?.id == 1
                                                                            ? $t('administrator')
                                                                            : personInChargeObject?.team?.value ?? ''
                                                                    }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-4"></div>
                                                        </div>
                                                        <div class="row mt-2" v-for="(pTem, k) in listItemPersons" :key="'p' + k">
                                                            <div class="col-sm-8">
                                                                <div class="d-flex align-items-center" style="gap: 5px">
                                                                    <span style="width: 60px; min-width: fit-content">サブ{{ k + 1 }}</span>
                                                                    <div class="position-relative col-sm-12">
                                                                        <Multiselect
                                                                            :id="`list-Item-Persons-${k}`"
                                                                            :value.sync="listItemPersons[k]"
                                                                            :options="filteredOptions(k)"
                                                                            :config="{
                                                                                trackBy: 'id',
                                                                                label: 'name',
                                                                                error: false,
                                                                                isRemove: false,
                                                                                existObject: true
                                                                            }"
                                                                            @remove="
                                                                                () => {
                                                                                    listItemPersons[k].id = '';
                                                                                    listItemPersons[k].name = '';
                                                                                    listItemPersons[k].team = '';
                                                                                    filteredOptions(k);
                                                                                }
                                                                            "
                                                                        />
                                                                    </div>

                                                                    <span style="min-width: fit-content">{{
                                                                        listItemPersons[k]?.role?.id == 1
                                                                            ? $t('administrator')
                                                                            : listItemPersons[k]?.team?.value ?? ''
                                                                    }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-4"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label class="col-sm-3">案件統括</label>
                                                    <div class="col-sm-8">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-fill">
                                                                <Multiselect
                                                                    :id="`select-project-management-object`"
                                                                    :value.sync="form.project_management"
                                                                    :options="listProjectManagement"
                                                                    :config="configProjectManagement"
                                                                />
                                                            </div>

                                                            <span class="ml-3" style="min-width: fit-content">{{
                                                                form.project_management?.role?.id == 1
                                                                    ? $t('administrator')
                                                                    : form.project_management?.team?.value ?? ''
                                                            }}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label class="col-sm-3">エスカレー担当</label>
                                                    <div class="col-sm-8">
                                                        <div class="row">
                                                            <div class="col-sm-8">
                                                                <div class="d-flex align-items-center">
                                                                    <span style="width: 60px; min-width: fit-content">メイン</span>
                                                                    <div class="col-sm-12">
                                                                        <!-- {{ customerTeamPic }} -->
                                                                        <Multiselect
                                                                            :id="`select-customer-team-pic`"
                                                                            :value.sync="customerTeamPic"
                                                                            :options="listDataCustomerTeamPic"
                                                                            :config="{
                                                                                trackBy: 'id',
                                                                                label: 'name',
                                                                                error: false,
                                                                                isRemove: false
                                                                            }"
                                                                            @select="changeDataCustomer()"
                                                                        />
                                                                    </div>

                                                                    <span class="ml-3" style="min-width: fit-content">{{
                                                                        customerTeamPic?.team?.value ?? ''
                                                                    }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-4"></div>
                                                        </div>
                                                        <div class="row mt-2" v-for="(spm, k) in subDataCustomerTeamPic" :key="'p' + k">
                                                            <div class="col-sm-8">
                                                                <div class="d-flex align-items-center" style="gap: 12px">
                                                                    <span style="width: 60px; min-width: fit-content">サブ</span>
                                                                    <div class="col-sm-12">
                                                                        <Multiselect
                                                                            :id="`select-sub-customer-team-pic${k}`"
                                                                            :value.sync="subDataCustomerTeamPic[k]"
                                                                            :options="OPTIONCUSTOMER(k)"
                                                                            :config="{
                                                                                trackBy: 'id',
                                                                                label: 'name',
                                                                                error: false,
                                                                                isRemove: false
                                                                            }"
                                                                            @remove="OPTIONCUSTOMER(k)"
                                                                        />
                                                                    </div>

                                                                    <span style="min-width: fit-content">{{
                                                                        subDataCustomerTeamPic[k]?.team?.value ?? ''
                                                                    }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-4"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label class="col-sm-3">アルバイト</label>
                                                    <div class="col-sm-9">
                                                        <div class="row mt-2" v-for="(eTem, k) in form.part_time_employees" :key="'e' + k">
                                                            <div class="col-sm-10">
                                                                <div class="d-flex w-100">
                                                                    <div class="w-100" style="position: relative">
                                                                        <Multiselect
                                                                            :id="`part_time_employees-${k}`"
                                                                            :value.sync="form.part_time_employees[k]"
                                                                            :options="filteredOptions1(k)"
                                                                            :config="{
                                                                                trackBy: 'id',
                                                                                label: 'name',
                                                                                error: false,
                                                                                isRemove: false,
                                                                                existObject: true
                                                                            }"
                                                                            @remove="
                                                                                () => {
                                                                                    filteredOptions1(k);
                                                                                    form.part_time_employees[k].id = '';
                                                                                    form.part_time_employees[k].name = '';
                                                                                }
                                                                            "
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-2">
                                                                <button
                                                                    type="button"
                                                                    class="btn btn-outline-info btn-sm"
                                                                    style="border-radius: 50%"
                                                                    v-if="k <= 0"
                                                                    @click="addEmploy()"
                                                                >
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    class="btn btn-sm btn-outline-danger"
                                                                    style="border-radius: 50%"
                                                                    v-if="k > 0"
                                                                    @click="removeEmploy(k)"
                                                                >
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-tab>

                                        <b-tab>
                                            <template v-slot:title>
                                                <span class="d-inline-block d-sm-none">
                                                    <i class="far fa-envelope"></i>
                                                </span>
                                                <span class="d-none d-sm-inline-block">賞品情報</span>
                                            </template>
                                            <div>
                                                <TabPrizes
                                                    :dataEventPrizes="form.event_prizes"
                                                    :dataPrizes.sync="arrayObjectPizes"
                                                    :listTypeTitle="listTypeTitle"
                                                />
                                            </div>
                                        </b-tab>

                                        <b-tab>
                                            <template v-slot:title>
                                                <span class="d-inline-block d-sm-none">
                                                    <i class="far fa-envelope"></i>
                                                </span>
                                                <span class="d-none d-sm-inline-block">実施結果登録</span>
                                            </template>
                                            <tab7
                                                :submitted.sync="submitted"
                                                :validate.sync="$v"
                                                :data-object.sync="form"
                                                :dataScope="tableScopeOrder"
                                                :dataApplycation="listCategoriesSub"
                                                :dataMasterCampaignType="listDataMasterCampaignType"
                                                :listScope.sync="listnumberOfInquiries"
                                                :dataCampaignSocialPlatform="listCampaignSocialPlatform"
                                            ></tab7>
                                        </b-tab>
                                    </b-tabs>
                                </form>
                                <ModalClient @refresh="getListMasterClients()"></ModalClient>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center align-items-center">
                    <div style="font-size: 20px; cursor: pointer">
                        <i v-if="isBookmarkCampaign" class="ri-bookmark-fill" style="color: #157efb" @click="handleUnsetBookmarkCampaign"></i>
                        <i v-else class="ri-bookmark-line" @click="handleSetBookmarkCampaign"></i>
                    </div>

                    <div class="text-center flex-fill box__button--custom">
                        <button
                            type="button"
                            class="btn btn-light"
                            @click="
                                $router.push({
                                    path: '/campaign/list'
                                })
                            "
                        >
                            {{ $t('btn.cancel') }}
                        </button>
                        <button
                            v-if="form.is_approved == 1 && checkPermission('campaign.edit')"
                            :disabled="form.is_locked == 1 || loadingRegister"
                            type="submit "
                            @click="handleGetDetail"
                            class="btn btn-info mx-3"
                        >
                            {{ $t('btn.detail') }}
                        </button>
                        <button
                            v-if="form.is_approved == 1 && checkPermission('campaign.edit')"
                            :disabled="form.is_locked == 1 || loadingRegister"
                            type="submit"
                            @click="formSubmit"
                            class="btn btn-primary"
                        >
                            {{ $t('btn.save') }}
                        </button>
                    </div>
                    <div>最終編集者：{{ form.updated_by ? form.updated_by.name : '' }}</div>
                </div>
            </Footer>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>
<style scoped>
.nav-tabs-custom .nav-item {
    white-space: nowrap;
}

.page-content.mystyle .form__body--card {
    max-width: 100%;
    margin-left: 0;
}

.custom__table--sticky td:last-child {
    position: relative;
    inset: 0;
}
</style>
