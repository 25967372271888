var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 text-nowrap",staticStyle:{"min-width":"150px"}},[_vm._v(_vm._s(_vm.$t('landingPage.settingDesgin.fontSize'))+" ")]),_c('div',{staticClass:"col-sm-4"},[_c('Multiselect',{attrs:{"id":`select-font_page`,"value":_vm.dataSetting.font_page,"options":_vm.listFontFamily,"config":{ trackBy: 'id', label: 'name', error: false, isRemove: false }},on:{"update:value":function($event){return _vm.$set(_vm.dataSetting, "font_page", $event)}}})],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 text-nowrap",staticStyle:{"min-width":"150px"}},[_vm._v(_vm._s(_vm.$t('landingPage.settingDesgin.squareType'))+" ")]),_c('div',{staticClass:"col-sm-9"},[_c('div',{staticClass:"d-flex"},_vm._l((_vm.listSquareType),function(item){return _c('div',{key:item.id + 's'},[_c('InputRadio',{staticClass:"mr-4",attrs:{"model":_vm.dataSetting.square_type,"name":`squareType`,"id":`squareType-${item.id}`,"value":item.id,"label":item.value},on:{"update:model":function($event){return _vm.$set(_vm.dataSetting, "square_type", $event)}}})],1)}),0)])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 text-nowrap",staticStyle:{"min-width":"150px"}},[_vm._v(_vm._s(_vm.$t('landingPage.settingDesgin.backgroundColor'))+" ")]),_c('div',{staticClass:"col-sm-1"},[_c('InputText',{staticStyle:{"min-width":"100px"},attrs:{"model":_vm.dataSetting.background_color,"config":{
                        type: 'color',
                        classCustom: 'form-control-color',
                        error: false
                    }},on:{"update:model":function($event){return _vm.$set(_vm.dataSetting, "background_color", $event)}}})],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 text-nowrap",staticStyle:{"min-width":"150px"}},[_vm._v(_vm._s(_vm.$t('landingPage.settingDesgin.titleColor'))+" ")]),_c('div',{staticClass:"col-sm-1"},[_c('InputText',{staticStyle:{"min-width":"100px"},attrs:{"model":_vm.dataSetting.title_color,"config":{
                        type: 'color',
                        classCustom: 'form-control-color',
                        error: false
                    }},on:{"update:model":function($event){return _vm.$set(_vm.dataSetting, "title_color", $event)}}})],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 text-nowrap",staticStyle:{"min-width":"150px"}},[_vm._v(_vm._s(_vm.$t('landingPage.settingDesgin.fontColor'))+" ")]),_c('div',{staticClass:"col-sm-1"},[_c('InputText',{staticStyle:{"min-width":"100px"},attrs:{"model":_vm.dataSetting.font_color,"config":{
                        type: 'color',
                        classCustom: 'form-control-color',
                        error: false
                    }},on:{"update:model":function($event){return _vm.$set(_vm.dataSetting, "font_color", $event)}}})],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 text-nowrap",staticStyle:{"min-width":"150px"}},[_vm._v(_vm._s(_vm.$t('landingPage.settingDesgin.buttonColor'))+" ")]),_c('div',{staticClass:"col-sm-1"},[_c('InputText',{staticStyle:{"min-width":"100px"},attrs:{"model":_vm.dataSetting.button_color,"config":{
                        type: 'color',
                        classCustom: 'form-control-color',
                        error: false
                    }},on:{"update:model":function($event){return _vm.$set(_vm.dataSetting, "button_color", $event)}}})],1)])])])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }