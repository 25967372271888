<template>
    <div>
        <div class="col-sm-12">
            <div v-if="listEventPrize?.length <= 0" class="card">
                <div class="text-center mb-5">
                    <button type="button" class="btn btn-outline-success btn-sm rounded" @click="handleAddTitle()">
                        <i class="fa fa-plus"></i>
                        賞品タイトルを追加
                    </button>
                </div>
            </div>

            <div v-for="(evt, idx) in listEventPrize" :key="idx" class="card">
                <div style="box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px; border-radius: 10px" class="p-2">
                    <div class="d-flex border-bottom p-2" style="border-bottom-color: #c1c1c17d !important">
                        <div class="col-sm-3">
                            <h4 v-b-tooltip.hover :title="evt.title">{{ truncateTextTable(evt.title, 20) ?? '' }}</h4>
                        </div>
                        <div class="col-sm-9">
                            <div class="d-flex" style="gap: 12px">
                                <button
                                    type="button"
                                    class="btn btn-outline-success btn-sm rounded-circle"
                                    style="width: 30px; height: 30px"
                                    @click="handleAddTitle()"
                                >
                                    <i class="fa fa-plus"></i>
                                </button>

                                <button
                                    type="button"
                                    class="btn btn-outline-warning btn-sm rounded-circle"
                                    style="width: 30px; height: 30px"
                                    @click="handleEditTitle(evt, idx)"
                                >
                                    <i class="fa fa-edit"></i>
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-outline-danger btn-sm rounded-circle"
                                    style="width: 30px; height: 30px"
                                    @click="handleDeleteTitle(idx)"
                                >
                                    <i class="fa fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div v-if="evt.prizes?.length <= 0" class="card">
                        <div class="text-center m-5">
                            <button type="button" class="btn btn-outline-success btn-sm rounded" @click="handleAddPrize(idx)">
                                <i class="fa fa-plus"></i>
                                {{ $t('landingPage.settingPrize.namePrize') }}
                            </button>
                        </div>
                    </div>

                    <Draggable :list="evt.prizes" ghost-class="ghost--eventPrize" @start="dragList = true" @end="dragList = false">
                        <div v-for="(itemPrize, k) in evt.prizes" :key="`prize-${k}-in-${idx}`" class="p-2">
                            <div class="card-body position-relative border-bottom">
                                <div class="d-flex flex-row mt-3">
                                    <div class="mr-3">
                                        <div class="d-flex align-items-center justify-content-center h-100">{{ itemPrize.id }}</div>
                                    </div>
                                    <div class="mr-3 col-sm-1" style="min-width: 120px">
                                        <img
                                            :src="itemPrize?.image ? itemPrize?.image : imagePrizeDefault"
                                            alt
                                            class="img-fluid mx-auto d-block rounded h-100 w-100"
                                            style="object-fit: contain"
                                        />
                                    </div>
                                    <div>
                                        <h5>{{ itemPrize.name }}</h5>
                                        <p>
                                            <a :href="itemPrize.link">{{ itemPrize.link }} </a>
                                        </p>
                                        <div class="p-1" v-html="truncateTextTable(itemPrize.detail, 500) ?? ''"></div>
                                    </div>
                                </div>
                                <div class="position-absolute" style="top: 0; right: 0">
                                    <button
                                        type="button"
                                        class="btn btn-outline-success btn-sm rounded-circle"
                                        style="width: 30px; height: 30px"
                                        @click="handleAddPrize(idx)"
                                    >
                                        <i class="fa fa-plus"></i>
                                    </button>

                                    <button
                                        type="button"
                                        class="btn btn-outline-warning btn-sm rounded-circle mx-3"
                                        style="width: 30px; height: 30px"
                                        @click="handleEditPrize(itemPrize, `${k}-${idx}`, idx)"
                                    >
                                        <i class="fa fa-edit"></i>
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-outline-danger btn-sm rounded-circle"
                                        style="width: 30px; height: 30px"
                                        @click="handleDeletePrize(idx, k)"
                                    >
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
            </div>

            <ModalActionTitle
                v-if="configModalActionTitle.model"
                :config="configModalActionTitle"
                :dataTitle="formTitle"
                :optionTypes="masterTypes"
                @updateData="eventUpdateDataTitle"
                @addData="eventAddDataTitle"
            />

            <ModalActionPrize
                v-if="configModalActionPrize.model"
                :config="configModalActionPrize"
                :dataPrize="formPrize"
                :listPrizeRegistered="prizeRegistered"
                @updateData="eventUpdateDataPrize"
                @addData="eventAddDataPrize"
            />
        </div>
    </div>
</template>

<script>
import { configModalActionPrize, configModalActionTitle } from './constants';
import { truncateText } from '@/utils/format';

import ModalActionPrize from './modal-action-prize.vue';
import ModalActionTitle from './modal-action-title.vue';
import Draggable from 'vuedraggable';

export default {
    name: 'SettingPrizeList',
    components: {
        ModalActionPrize,
        ModalActionTitle,
        Draggable
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        },
        masterTypes: {
            type: Array,
            required: true,
            default: () => []
        }
    },

    data() {
        return {
            configModalActionPrize: configModalActionPrize,
            configModalActionTitle: configModalActionTitle,
            formTitle: null,
            formPrize: null,
            dragList: false,
            listEventPrize: [],
            prizeRegistered: [],
            imagePrizeDefault:
                'https://img.freepik.com/premium-vector/gold-cup-medal-first-place-victory-prize-golden-bowl-winning-achievement-trophy-awards-isolated-white-background-vector-illustration-flat-design-medal-red-ribbon_153097-1550.jpg'
        };
    },

    methods: {
        isActiveModalEdit(idActive) {
            return idActive == this.configModalActionPrize.id;
        },

        handleAddTitle() {
            this.configModalActionTitle.title = 'Add Title';
            this.formTitle = {
                idxTitle: this.listEventPrize.length,
                title: '',
                types: {
                    master_id: '',
                    note: '',
                    listCheckedLv1: [],
                    listCheckedLv2: [],
                    children_data: []
                },
                is_high_coupon_value: false,
                prizes: []
            };
            this.configModalActionTitle.model = true;
        },

        handleEditTitle(title, idx) {
            this.configModalActionTitle.id = idx;
            this.configModalActionTitle.title = 'Edit Title';
            this.formTitle = title;
            this.configModalActionTitle.model = true;
        },

        handleDeleteTitle(idx) {
            this.listEventPrize.splice(idx, 1);
        },

        eventAddDataTitle(titleNew) {
            this.listEventPrize.push(titleNew);
            this.handleMapPrizes(this.listEventPrize);
        },
        eventUpdateDataTitle(titleNew) {
            let indexTitle = this.listEventPrize.findIndex((item) => item.idxTitle == titleNew.idxTitle);
            if (indexTitle <= -1) return;
            this.listEventPrize[indexTitle] = titleNew;
            this.handleMapPrizes(this.listEventPrize);
        },

        handleAddPrize(idx) {
            this.configModalActionPrize.title = 'Add Prize';
            this.prizeRegistered = this.listEventPrize[idx].prizes;
            this.formPrize = {
                idxTitle: idx,
                idxPrize: this.listEventPrize[idx].prizes.length,
                image: '',
                name: '',
                link: '',
                detail: '',
                target_number: '',
                status: ''
            };
            this.configModalActionPrize.model = true;
        },

        handleEditPrize(prize, idx, idxTitle) {
            this.prizeRegistered = this.listEventPrize[idxTitle].prizes;
            this.configModalActionPrize.id = idx;
            this.configModalActionPrize.title = 'Edit Prize';
            this.formPrize = prize;
            this.configModalActionPrize.model = true;
        },

        handleDeletePrize(idx, k) {
            this.listEventPrize[idx].prizes.splice(k, 1);
        },

        eventAddDataPrize(prizeNew) {
            this.listEventPrize[prizeNew.idxTitle].prizes.push(prizeNew);
            this.handleMapPrizes(this.listEventPrize);
        },

        eventUpdateDataPrize(prizeNew) {
            let indexPrize = this.listEventPrize[prizeNew.idxTitle].prizes.findIndex((item) => item.idxPrize == prizeNew.idxPrize);
            if (indexPrize <= -1) return;
            this.listEventPrize[prizeNew.idxTitle].prizes[indexPrize] = prizeNew;
            this.handleMapPrizes(this.listEventPrize);
        },

        handleMapPrizes(listEventPrize) {
            this.dataForm.event_prizes = listEventPrize.map((evt) => {
                return {
                    ...(evt.id !== null &&
                        evt.id !== undefined && {
                            id: evt.id
                        }),
                    title: evt.title,
                    types: evt.types,
                    is_high_coupon_value: evt.is_high_coupon_value,
                    prizes: evt.prizes.map((pz) => {
                        return {
                            ...(pz.id !== null &&
                                pz.id !== undefined && {
                                    id: pz.id
                                }),
                            image: pz.image,
                            name: pz.name,
                            link: pz.link,
                            detail: pz.detail,
                            target_number: pz.target_number,
                            status: pz.status
                        };
                    })
                };
            });
        },

        truncateTextTable(text, length) {
            return truncateText(text, length);
        }
    }
};
</script>

<style lang="scss" scoped>
.ghost--eventPrize {
    opacity: 0.5;
    background-color: #f8f9fa;
    border: dashed 1px #ccc;
}
</style>
