import { LIST_INQUIRY_FIT } from '@/config/var-common';

function funcRecivedDataApplycation(listApplycation, listFollowers, newApplycation, dataMasterCampaignType, dataObject) {
    listApplycation = [];
    listFollowers = [];
    if (newApplycation.length > 0) {
        const getID = newApplycation.map((item) => item.category_id);
        const flatMapOne = dataMasterCampaignType.flatMap((lv1) => {
            return lv1.data.flatMap((lv2) => lv2);
        });

        flatMapOne.forEach((item1) => {
            if (getID.includes(item1.id)) {
                const foundObj = dataObject.number_of_applications?.find((numberItem) => numberItem?.master?.id === item1.id);
                const objPlatformFollower = dataObject.platform_followers_number?.find((platFollower) => platFollower?.master?.id === item1.id);

                listApplycation.push({
                    master: {
                        id: item1.id,
                        value: item1.value
                    },
                    quantity: foundObj?.quantity ?? 0,
                    configQuantity: {
                        isNumber: true,
                        maxlength: 10,
                        error: false,
                        errorField: item1.value,
                        classCustom: 'text-center'
                    }
                });

                listFollowers.push({
                    master: {
                        id: item1.id,
                        value: item1.value
                    },
                    initial_quantity: objPlatformFollower?.initial_quantity ?? 0,
                    configInitialQuantity: {
                        isNumber: true,
                        maxlength: 10,
                        error: false,
                        errorField: item1.value,
                        classCustom: 'text-center'
                    },
                    final_quantity: objPlatformFollower?.final_quantity ?? 0,
                    configFinalQuantity: {
                        isNumber: true,
                        maxlength: 10,
                        error: false,
                        errorField: item1.value,
                        classCustom: 'text-center'
                    },
                    increase_quantity: objPlatformFollower?.increase_quantity ?? 0,
                    configIncreaseQuantity: {
                        isNumber: true,
                        maxlength: 10,
                        error: false,
                        errorField: item1.value,
                        classCustom: 'text-center'
                    }
                });
            } else {
                listApplycation = listApplycation.filter((itemA) => itemA.master.id !== item1.id);
                listFollowers = listFollowers.filter((itemA) => itemA.master.id !== item1.id);
            }
        });
    }
    return {
        listApplycation,
        listFollowers
    };
}

function funcRecivedDataScope(newScope, listScope, dataObject) {
    listScope = [];
    if (newScope.length > 0) {
        const flatMapOne = newScope.flatMap((lv1) => {
            return lv1.data.flatMap((lv2) => lv2);
        });
        for (let item2 of flatMapOne) {
            if (LIST_INQUIRY_FIT.includes(item2.id)) {
                const foundObj = dataObject.number_of_inquiries?.find((numberItem) => numberItem?.master_order_scope?.id === item2.id);
                listScope.push({
                    master_order_scope: {
                        id: item2.id,
                        value: item2.value
                    },
                    quantity: foundObj?.quantity ?? 0,
                    configQuantity: {
                        isNumber: true,
                        maxlength: 10,
                        error: false,
                        errorField: item2.value,
                        classCustom: 'text-center'
                    }
                });
            } else {
                listScope = listScope?.filter((itemB) => itemB?.master?.id !== item2.id);
                dataObject.number_of_inquiries = listScope;
            }
        }
    }
    return listScope;
}

function funcChangeMediaCosts(paramMedia, listCheckedMediaCosts, formMediaCosts, form) {
    if (listCheckedMediaCosts.includes(paramMedia.id)) {
        formMediaCosts.push({
            master: {
                id: paramMedia.id,
                value: paramMedia.value
            },
            note: {
                detail: ''
            }
        });
    } else {
        formMediaCosts = formMediaCosts.filter((item) => item?.master?.id !== paramMedia.id);
    }
    form.social_platforms = formMediaCosts;
    return formMediaCosts;
}

export { funcRecivedDataApplycation, funcRecivedDataScope, funcChangeMediaCosts };
