import i18n from '@/i18n';

const configFormTargetProduct = () => {
    return {
        configFieldTitleTargetProduct: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('landingPage.settingTargetProduct.titleTargetProduct'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'max255']
        },
        configFieldDetailTargetProduct: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('landingPage.settingTargetProduct.detailTargetProduct'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldLinkTargetProduct: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('landingPage.settingTargetProduct.linkTargetProduct'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        }
    };
};

export { configFormTargetProduct };
