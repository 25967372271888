<template>
    <div>
        <div class="col-lg-12">
            <b-tabs justified nav-class="sub__tab__design--landing__page nav-tabs-custom " content-class="p-3 text-muted ">
                <b-tab v-for="(tab, index) in tabDesign" :key="index" class="border-0">
                    <template v-slot:title>
                        <span class="">{{ tab.name }}</span>
                    </template>
                    <div>
                        <SettingOverall v-if="tab.id == 1" :dataSetting="dataForm" />
                        <SettingBlocks v-else-if="tab.id == 2" :dataSetting="dataForm" />
                        <SettingBlockDetail v-else :dataSetting="dataForm" />
                    </div>
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
import SettingOverall from './setting-overall.vue';
import SettingBlocks from './setting-blocks.vue';
import SettingBlockDetail from './setting-block_detail.vue';

export default {
    name: 'SettingDesginLP',
    components: {
        SettingOverall,
        SettingBlocks,
        SettingBlockDetail
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {
            tabDesign: [
                { id: 1, name: 'デザイン全体設定' },
                { id: 2, name: 'ブロック設定' },
                { id: 3, name: 'ブロック詳細' }
            ]
        };
    }
};
</script>

<style lang="scss">
.sub__tab__design--landing__page {
    &.nav-tabs-custom {
        border-bottom: none;
        .nav-link {
            border-top: 2px solid #f1f5f7;
            &.active {
                color: inherit;
            }
            &::after {
                top: -1px;
                background: #acacac;
            }
        }
    }
}
</style>
