<template>
    <div>
        <ModalCommon :id="`configModalActionTitle-${config?.id ?? ''}`" :config="config" @save="handleSave()" @close="handleClose()">
            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" for="adsfasđs" style="min-width: 150px"
                    >{{ $t('landingPage.settingPrize.titlePrize') }} <span class="text-danger">*</span></label
                >
                <div class="col-sm-9">
                    <InputText
                        :model.sync="dataFormTitle.title"
                        :config.sync="configFormTitleLP.configFieldTitlePrize"
                        @blur="handleValidateField(configFormTitleLP.configFieldTitlePrize)"
                    />
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-3 mw--form" for="prizeLessStatus">{{ this.$t('pageCampaign.tabPrize.type') }}</label>
                <div class="col-sm-9">
                    <Multiselect
                        :id="`select-prize-type-title-${config?.id}`"
                        :value.sync="dataFormTitle.types.master_id"
                        :options="optionTypes"
                        :config.sync="configFormTitleLP.configFieldSelectTypeTitle"
                        @select="handleSelectType()"
                        @remove="handleRemoveType()"
                    ></Multiselect>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-3 mw--form" for="prizeLessStatus">{{ dataFormTitle.types?.master_id?.value ?? '' }}</label>
                <div class="col-sm-9">
                    <div v-for="(item, idx) in optionTypes" :key="idx">
                        <div v-if="renderListLv1(item, dataFormTitle.types?.master_id?.id).check" class="d-flex flex-wrap">
                            <div v-for="(item, idx) in renderListLv1(item, dataFormTitle.types?.master_id?.id).itemLv1" :key="idx">
                                <InputCheckBox
                                    class="mr-3 mb-2"
                                    :model.sync="dataFormTitle.types.listCheckedLv1"
                                    :id="`check-master_id-type${item.id}`"
                                    :value="item.id"
                                    :label="item.value"
                                    @change="handleCheckBoxLv1(item, dataFormTitle.types)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-for="(item, idx) in optionTypes" :key="idx">
                <div v-if="dataFormTitle.types?.master_id?.value == 'デジタル' && item.value == 'デジタル'">
                    <div v-for="(ite, idx) in item.data" :key="idx">
                        <div v-if="dataFormTitle.types.listCheckedLv1.includes(ite.id) && ite.data.length" class="form-group row">
                            <label class="col-sm-3 mw--form" for="prizeLessStatus">{{ ite?.value ?? '' }}</label>
                            <div class="col-sm-9">
                                <div class="d-flex flex-wrap">
                                    <div v-for="(it, idx) in ite.data" :key="idx">
                                        <InputCheckBox
                                            class="mr-3 mb-2"
                                            :model.sync="dataFormTitle.types.listCheckedLv2"
                                            :id="`check-id-type_lv2${it.id}`"
                                            :value="it.id"
                                            :label="it.value"
                                            @change="handleChangeCheckBoxLV2(it, dataFormTitle.types)"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-for="(check1, idx) in dataFormTitle.types.children_data" :key="`check1-${idx}`">
                        <div v-for="(check2, idxe) in check1.children_data" :key="`check2-${idxe}`">
                            <div v-if="dataFormTitle.types.listCheckedLv2.includes(check2.master_id?.id || check2.master_id)" class="form-group row">
                                <label class="col-sm-3">{{ check2?.master_id?.value }}</label>
                                <div class="col-sm-9">
                                    <InputText
                                        :id="`electronic_money_point_info`"
                                        :model.sync="check2.note.electronic_money_point_info"
                                        :config.sync="check2.note.configFieldElectronic"
                                        @blur="handleValidateField(check2.note.configFieldElectronic)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="dataFormTitle.types?.master_id?.id" class="form-group row">
                <label class="col-sm-3 mw--form" for="prizeLessStatus">{{ $t('pageCampaign.tabPrize.typeTitle.over500') }}</label>
                <div class="col-sm-9">
                    <InputCheckBox
                        class="mr-3 mb-2"
                        :model.sync="dataFormTitle.is_high_coupon_value"
                        :id="`check-${dataFormTitle.types?.master_id?.value ?? ''}`"
                        :value="false"
                        :label="''"
                    />
                </div>
            </div>
        </ModalCommon>
    </div>
</template>

<script>
import { InputText, InputCheckBox } from '@/components/Input';
import { configFormTitleLP } from './constants';
import { validateField, validateAllField } from '@/utils/validate';
import { initObjectTypeTitle } from '@/components/Campaign/TabPrize/index';

import ModalCommon from '@/components/Modal/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';

export default {
    name: 'ModalEditPrize',
    components: {
        InputText,
        InputCheckBox,
        ModalCommon,
        Multiselect
    },
    props: {
        config: {
            type: Object,
            required: true,
            default: () => null
        },
        dataTitle: {
            type: Object,
            required: true,
            default: () => null
        },
        optionTypes: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    data() {
        return {
            configFormTitleLP: configFormTitleLP,
            dataFormTitle: this.dataTitle,
            dataFormTitleCopy: JSON.parse(JSON.stringify(this.dataTitle))
        };
    },
    methods: {
        handleValidateField(configField) {
            validateField(configField);
        },

        handleSave() {
            let checkValidate = validateAllField(configFormTitleLP);
            if (!checkValidate) return;
            if (this.dataFormTitle?.id || this.dataFormTitle?.actionTitle == 'Edit') {
                this.$emit('updateData', this.dataFormTitle);
            } else {
                this.dataFormTitle.actionTitle = 'Edit';
                this.$emit('addData', this.dataFormTitle);
            }
            this.config.model = false;
        },

        handleClose() {
            let { configFieldTitlePrize } = this.configFormTitleLP;
            configFieldTitlePrize.error = false;
            this.dataFormTitle = this.dataFormTitleCopy;
            this.$emit('updateData', this.dataFormTitle);
            this.config.model = false;
        },

        handleSelectType() {
            this.dataFormTitle.types = initObjectTypeTitle();
            this.dataFormTitle.is_high_coupon_value = false;
        },

        handleRemoveType() {
            this.dataFormTitle.types = initObjectTypeTitle();
            this.dataFormTitle.is_high_coupon_value = false;
        },

        renderListLv1(idOrigin, checkIdLv1) {
            return {
                check: idOrigin.id === checkIdLv1,
                itemLv1: idOrigin.data
            };
        },

        handleCheckBoxLv1(obj1, form) {
            if (form.listCheckedLv1.includes(obj1.id)) {
                form.children_data.push(initObjectTypeTitle(obj1, 'change'));
            } else {
                for (const targetChild of obj1.data) {
                    form.listCheckedLv2 = form.listCheckedLv2.filter((checked2) => checked2 !== targetChild.id);
                }
                form.children_data = form.children_data.filter((itemTarget) => itemTarget.master_id?.id !== obj1.id);
            }
        },

        handleChangeCheckBoxLV2(obj2, form) {
            const found2 = form.children_data.find((ot) => ot.master_id == obj2.parent_id || ot.master_id.id == obj2.parent_id);
            if (form.listCheckedLv2.includes(obj2.id)) {
                found2.children_data.push(initObjectTypeTitle(obj2, 'change'));
            } else {
                found2.children_data = found2.children_data.filter((itemTarget) => {
                    let condition = itemTarget.master_id?.id ?? itemTarget.master_id ?? null;
                    return condition !== obj2.id;
                });
            }
        }
    },
    watch: {
        'config.model': {
            handler: function (newShow) {
                if (newShow) {
                    this.dataFormTitleCopy = JSON.parse(JSON.stringify(this.dataTitle));
                    this.isLoading = true;
                }
            },
            immediate: true,
            deep: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
